import { ApiErrorResponse } from 'helpers/interfaces';

function isApiErrorResponse(error: unknown): error is ApiErrorResponse {
  return (
    error !== null &&
    typeof error === 'object' &&
    'status' in error &&
    'title' in error &&
    'detail' in error &&
    'violations' in error &&
    Array.isArray(error.violations) &&
    error.violations.every(
      (violation) =>
        typeof violation === 'object' &&
        typeof violation.propertyPath === 'string' &&
        typeof violation.message === 'string',
    )
  );
}

export default isApiErrorResponse;
