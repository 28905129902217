import { ApiFilters } from 'helpers/interfaces';

function buildQueryString<T>(filters?: ApiFilters<T>) {
  if (!filters) {
    return '';
  }

  const query = new URLSearchParams();

  for (const key in filters) {
    if (filters[key] === undefined) {
      continue;
    }
    query.append(key, String(filters[key]));
  }

  return `?${query.toString()}`;
}

export default buildQueryString;
