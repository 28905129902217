import React from 'react';
import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { currentProject, projects } from 'store/projects/selectors';
import { Project } from 'store/projects/interfaces';
import { setCurrentProjectById } from 'store/projects/projects';
import {
  projectSelectWidth,
  topBarHeight,
} from 'components/Layout/utils/consts';
import theme from 'config/mui/theme';

function ProjectSwitchSelect() {
  const allProjects = useAppSelector(projects);
  const project = useAppSelector(currentProject);
  const dispatch = useAppDispatch();

  const handleSwitchProject = (projectId: Project['id']) => {
    dispatch(setCurrentProjectById({ projectId }));
  };

  return (
    <FormControl
      sx={{
        px: 2,
        width: '100%',
        maxWidth: projectSelectWidth,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1.5,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: 44,
          minHeight: 44,
          bgcolor: theme.palette.info.lighter,
          borderRadius: theme.shape['borderRadius-xs'],
        }}
      >
        <Typography variant="h6" color="info.dark" textTransform="uppercase">
          {project?.name[0]}
        </Typography>
      </Box>
      <Select
        variant="standard"
        fullWidth
        value={project?.id || ''}
        IconComponent={ExpandMoreIcon}
        onChange={(e) => {
          handleSwitchProject(e.target.value);
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              ml: { sm: -3.5 },
              width: '100%',
              maxWidth: { sm: projectSelectWidth },
            },
          },
        }}
        sx={{
          overflow: 'hidden',
          height: topBarHeight,
          '&::before, &::after, &:hover:not(.Mui-disabled, .Mui-error)::before':
            {
              border: 'none',
            },
        }}
      >
        {allProjects?.map((project) => (
          <MenuItem key={project.id} value={project.id}>
            <Typography sx={{ textWrap: 'wrap' }}>{project.name}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default ProjectSwitchSelect;
