import { getApiToken } from 'store/utils/getApiToken';

export const getDefaultHeaders = () => ({
  'Content-Type': 'application/ld+json',
  Accept: 'application/ld+json',
  Authorization: getApiToken() || '',
});

export const getDefaultUpdateHeaders = () => ({
  'Content-Type': 'application/merge-patch+json',
  Accept: 'application/ld+json',
  Authorization: getApiToken() || '',
});

export const getDefaultUnauthorizedHeaders = () => ({
  'Content-Type': 'application/ld+json',
  Accept: 'application/ld+json',
});
