import { createSlice } from '@reduxjs/toolkit';
import defaultState from 'store/articles/defaultState';
import {
  createArticle,
  editArticle,
  fetchArticles,
} from 'store/articles/thunks';

export const articles = createSlice({
  name: 'articles',
  initialState: defaultState,
  reducers: {
    resetArticlesState: (draft) => {
      draft.articles = defaultState.articles;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchArticles.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(fetchArticles.fulfilled, (draft, action) => {
      const { 'hydra:member': articles } = action.payload;
      draft.articles = articles;
      draft.isRequestPending = false;
    });
    builder.addCase(fetchArticles.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(createArticle.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(createArticle.fulfilled, (draft, action) => {
      if (!draft?.articles) {
        return;
      }
      draft.articles.push(action.payload);
      draft.isRequestPending = false;
    });
    builder.addCase(createArticle.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(editArticle.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(editArticle.fulfilled, (draft, action) => {
      if (!draft?.articles) {
        return;
      }
      const index = draft.articles.findIndex(
        (article) => article.id === action.payload.id,
      );
      if (index >= 0) {
        draft.articles[index] = action.payload;
      }
      draft.isRequestPending = false;
    });
    builder.addCase(editArticle.rejected, (draft) => {
      draft.isRequestPending = false;
    });
  },
});

export const { resetArticlesState } = articles.actions;

export default articles.reducer;
