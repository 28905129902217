import React from 'react';
import { createSvgIcon } from '@mui/material';

const DocumentlyIcon = createSvgIcon(
  <svg
    width="26"
    height="32"
    viewBox="0 0 26 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_63_44)">
      <path
        d="M0.333344 5.12C0.333344 3.32783 0.333344 2.43175 0.678489 1.74723C0.982088 1.14511 1.46653 0.655573 2.06237 0.348779C2.73976 0 3.62651 0 5.40001 0H9.83333C12.7762 0 14.2476 0 15.4712 0.245948C20.4959 1.25595 24.4238 5.2252 25.4233 10.3028C25.6667 11.5393 25.6667 13.0262 25.6667 16C25.6667 18.9738 25.6667 20.4607 25.4233 21.6972C24.4238 26.7748 20.4959 30.744 15.4712 31.754C14.2476 32 12.7762 32 9.83334 32H5.40001C3.62651 32 2.73976 32 2.06237 31.6512C1.46653 31.3444 0.982088 30.8549 0.678489 30.2528C0.333344 29.5682 0.333344 28.6722 0.333344 26.88V5.12Z"
        fill="black"
      />
      <path
        d="M9.83335 0H6.66668L0.560261 24.6828C0.475296 25.0262 0.432814 25.1979 0.403014 25.3718C0.376533 25.5263 0.357561 25.6821 0.346166 25.8385C0.333344 26.0144 0.333344 26.1914 0.333344 26.5454V26.88C0.333344 28.6722 0.333344 29.5682 0.678489 30.2528C0.982088 30.8549 1.46653 31.3444 2.06237 31.6512C2.73976 32 3.62651 32 5.40001 32H9.83333C12.7762 32 14.2476 32 15.4712 31.754C20.4959 30.744 24.4238 26.7748 25.4233 21.6972C25.6667 20.4607 25.6667 18.9738 25.6667 16C25.6667 13.0262 25.6667 11.5393 25.4233 10.3028C24.4238 5.2252 20.4959 1.25595 15.4712 0.245948C14.2476 0 12.7762 0 9.83335 0Z"
        fill="#0071FF"
      />
      <path
        d="M6.66668 0C6.66668 3.53462 9.50221 6.4 13 6.4L1.5255 28.1412C1.17529 28.8047 1.00018 29.1365 0.840601 29.1838C0.703074 29.2246 0.554481 29.1871 0.452119 29.0857C0.333344 28.9681 0.333344 28.592 0.333344 27.84V25.6L6.66668 0Z"
        fill="#35C9FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_63_44">
        <rect
          width="25.3333"
          height="32"
          fill="white"
          transform="translate(0.333344)"
        />
      </clipPath>
    </defs>
  </svg>,
  'DocumentlyIcon',
);

export default DocumentlyIcon;
