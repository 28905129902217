import { configureStore } from '@reduxjs/toolkit';
import reducers from 'store/reducers';
import { setupListeners } from '@reduxjs/toolkit/query';
import unauthorizedErrorMiddleware from 'store/authorization/unauthorizedErrorMiddleware';

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['projects/exportProject/fulfilled'],
      },
    }).concat([unauthorizedErrorMiddleware]),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
