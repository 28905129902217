import { createTheme } from '@mui/material/styles';
import { topBarHeight } from 'components/Layout/utils/consts';

const theme = createTheme({
  cssVariables: true,
  colorSchemes: {
    light: {
      palette: {
        text: {
          primary: '#1D1E20',
          secondary: '#4F5459',
          tertiary: '#777E88',
        },
        primary: {
          main: '#0071FF',
          dark: '#0058C6',
          darker: '#003372',
          light: '#A1B7FF',
          lighter: '#E4EAFF',
          contrastText: '#FFFFFF',
          _states: {
            hover: 'rgba(0, 113, 255, 0.04)',
            selected: 'rgba(0, 113, 255, 0.08)',
            focus: 'rgba(0, 113, 255, 0.12)',
            focusVisible: 'rgba(0, 113, 255, 0.3)',
            outlinedBorder: 'rgba(0, 113, 255, 0.24)',
          },
        },
        secondary: {
          main: '#35C9FF',
          dark: '#2488AD',
          darker: '#0F3948',
          light: '#A6E0FF',
          lighter: '#E5F5FF',
          contrast: '#0F3948',
          _states: {
            hover: 'rgba(53, 201, 255, 0.04)',
            selected: 'rgba(53, 201, 255, 0.08)',
            focus: 'rgba(53, 201, 255, 0.12)',
            focusVisible: 'rgba(53, 201, 255, 0.3)',
            outlinedBorder: 'rgba(53, 201, 255, 0.24)',
          },
        },
        background: {
          default: '#FFFFFF',
          grey: '#F0F1F3',
          bombay: '#B4B6BD',
        },
        action: {
          active: 'rgba(29, 30, 32, 0.56)',
          hover: 'rgba(29, 30, 32, 0.04)',
          selected: 'rgba(29, 30, 32, 0.08)',
          focus: 'rgba(29, 30, 32, 0.12)',
          disabled: 'rgba(29, 30, 32, 0.38)',
          disabledBackground: 'rgba(29, 30, 32, 0.12)',
        },
        error: {
          main: '#EF0024',
          dark: '#930016',
          darker: '#40000A',
          light: '#FF888C',
          lighter: '#FFDADB',
          contrastText: '#FFFFFF',
          _states: {
            hover: 'rgba(239, 0, 36, 0.04)',
            selected: 'rgba(239, 0, 36, 0.08)',
            focusVisible: 'rgba(239, 0, 36, 0.3)',
            outlinedBorder: 'rgba(239, 0, 36, 0.24)',
          },
        },
        warning: {
          main: '#CA5100',
          dark: '#793000',
          darker: '#311300',
          light: '#FF8B67',
          lighter: '#FFDAD3',
          contrastText: '#FFFFFF',
          _states: {
            hover: 'rgba(245, 98, 0, 0.04)',
            selected: 'rgba(245, 98, 0, 0.08)',
            focusVisible: 'rgba(245, 98, 0, 0.3)',
            outlinedBorder: 'rgba(245, 98, 0, 0.24)',
          },
        },
        info: {
          main: '#9C47F9',
          dark: '#6514AB',
          darker: '#2A0847',
          light: '#C098FB',
          lighter: '#E9DDFE',
          contrastText: '#FFFFFF',
          _states: {
            hover: 'rgba(156, 71, 249, 0.04)',
            selected: 'rgba(156, 71, 249, 0.08)',
            focusVisible: 'rgba(156, 71, 249, 0.3)',
            outlinedBorder: 'rgba(156, 71, 249, 0.24)',
          },
        },
        success: {
          main: '#3C870D',
          dark: '#245108',
          darker: '#0E2003',
          light: '#56C213',
          lighter: '#CFEAC8',
          _states: {
            hover: 'rgba(86, 194, 19, 0.04)',
            selected: 'rgba(86, 194, 19, 0.08)',
            focusVisible: 'rgba(86, 194, 19, 0.3)',
            outlinedBorder: 'rgba(86, 194, 19, 0.24)',
          },
          contrast: '#FFFFFF',
        },
        common: {
          white_states: {
            outlinedBorder: 'rgba(255, 255, 255, 0.24)',
            main: '#FFFFFF',
          },
        },
        divider: '#E0E1E5',
        _components: {
          avatar: {
            fill: '#E4EAFF',
          },
          input: {
            outlined: {
              enabledBorder: '#CCCED4',
              hoverBorder: '#B4B6BD',
            },
          },
          switch: {
            knobFillEnabled: '#F8F8F9',
            knowFillDisabled: '#F0F1F3',
          },
          chip: {
            defaultEnabledBorder: '#B4B6BD',
          },
          tooltip: {
            fill: '#FFFFFF',
          },
          backdrop: {
            fill: 'rgba(29, 30, 32, 0.48)',
          },
          appBar: {
            defaultFill: '#F0F1F3',
          },
          breadcrumbs: {
            collapseFill: '#F0F1F3',
          },
          alert: {
            error: {
              color: '#40000A',
              background: '#FFDADB',
            },
            warning: {
              color: '#311300',
              background: '#FFDAD3',
            },
            info: {
              color: '#2A0847',
              background: '#E9DDFE',
            },
            success: {
              color: '#0E2003',
              background: '#CFEAC8',
            },
          },
          stepper: {
            connector: '#B4B6BD',
          },
        },
        _native: {
          'scrollbar-bg': '#E0E1E5',
        },
      },
    },
    dark: {
      palette: {
        text: {
          tertiary: 'rgba(255, 255, 255, 0.7)',
        },
        primary: {
          main: '#C6D2FF',
          dark: '#7298FF',
          darker: '#0065E4',
          light: '#F2F5FF',
          lighter: '#F2F5FF',
          contrastText: 'rgba(0, 0, 0, 0.87)',
          _states: {
            hover: 'rgba(198, 210, 255, 0.08)',
            selected: 'rgba(198, 210, 255, 0.16)',
            focus: 'rgba(198, 210, 255, 0.12)',
            focusVisible: 'rgba(198, 210, 255, 0.3)',
            outlinedBorder: 'rgba(198, 210, 255, 0.5)',
          },
        },
        secondary: {
          main: '#C8EBFF',
          dark: '#7BD5FF',
          darker: '#2FB4E4',
          light: '#F2FAFF',
          lighter: '#F2FAFF',
          _states: {
            hover: 'rgba(229, 245, 255, 0.08)',
            selected: 'rgba(229, 245, 255, 0.16)',
            focus: 'rgba(229, 245, 255, 0.12)',
            focusVisible: 'rgba(229, 245, 255, 0.3)',
            outlinedBorder: 'rgba(229, 245, 255, 0.5)',
          },
        },
        background: {
          grey: '#111212',
        },
        error: {
          main: '#EF0024',
          dark: '#930016',
          darker: '#680010',
          light: '#FF888C',
          lighter: '#FFDADB',
          contrastText: '#FFFFFF',
          _states: {
            hover: 'rgba(255, 83, 90, 0.08)',
            selected: 'rgba(255, 83, 90, 0.16)',
            focusVisible: 'rgba(255, 83, 90, 0.3)',
            outlinedBorder: 'rgba(255, 83, 90, 0.5)',
          },
        },
        warning: {
          main: '#F56200',
          dark: '#793000',
          darker: '#793000',
          light: '#FF8B67',
          lighter: '#FF8B67',
          contrastText: 'rgba(0, 0, 0, 0.87)',
          _states: {
            hover: 'rgba(255, 180, 162, 0.08)',
            selected: 'rgba(255, 180, 162, 0.16)',
            focusVisible: 'rgba(255, 180, 162, 0.3)',
            outlinedBorder: 'rgba(255, 180, 162, 0.5)',
          },
        },
        info: {
          main: '#AD73FA',
          dark: '#6514AB',
          darker: '#6514AB',
          light: '#C098FB',
          lighter: '#C098FB',
          contrastText: 'rgba(0, 0, 0, 0.87)',
          _states: {
            hover: 'rgba(212, 187, 252, 0.08)',
            selected: 'rgba(212, 187, 252, 0.16)',
            focusVisible: 'rgba(212, 187, 252, 0.3)',
            outlinedBorder: 'rgba(212, 187, 252, 0.5)',
          },
        },
        success: {
          main: '#49A410',
          dark: '#245108',
          darker: '#245108',
          light: '#56C213',
          lighter: '#56C213',
          _states: {
            hover: 'rgba(207, 234, 200, 0.08)',
            selected: 'rgba(207, 234, 200, 0.16)',
            focusVisible: 'rgba(207, 234, 200, 0.3)',
            outlinedBorder: 'rgba(207, 234, 200, 0.5)',
          },
          contrast: 'rgba(0, 0, 0, 0.87)',
        },
        elevation: {
          outlined: '#E0E1E5',
        },
        _components: {
          avatar: {
            fill: '#656A72',
          },
          switch: {
            knobFillEnabled: '#CCCED4',
            knowFillDisabled: '#656A72',
          },
          chip: {
            defaultEnabledBorder: '#4F5459',
          },
          breadcrumbs: {
            collapseFill: '#656A72',
          },
          stepper: {
            connector: '#656A72',
          },
        },
        _native: {
          'scrollbar-bg': '#4F5459',
        },
        common: {
          white_states: {
            main: '#FFFFFF',
          },
        },
      },
    },
  },
  shadows: [
    'none',
    '0px 3px 2px -2px rgba(58, 61, 64, 0.08), 0px 2px 2px 0px rgba(58, 61, 64, 0.05), 0px 2px 4px 0px rgba(58, 61, 64, 0.04);',
    '0px 3px 1px -2px rgba(58, 61, 64, 0.08), 0px 2px 2px 0px rgba(58, 61, 64, 0.05), 0px 1px 5px 0px rgba(58, 61, 64, 0.06);',
    '0px 3px 4px -2px rgba(58, 61, 64, 0.08), 0px 3px 6px 0px rgba(58, 61, 64, 0.08), 0px 1px 12px 0px rgba(58, 61, 64, 0.06);',
    '0px 2px 4px -1px rgba(58, 61, 64, 0.16), 0px 4px 5px 0px rgba(58, 61, 64, 0.05), 0px 1px 12px 0px rgba(58, 61, 64, 0.06);',
    '0px 3px 5px -1px rgba(58, 61, 64, 0.12), 0px 5px 8px 0px rgba(58, 61, 64, 0.08), 0px 1px 14px 0px rgba(58, 61, 64, 0.06);',
    '0px 3px 5px -1px rgba(58, 61, 64, 0.12), 0px 6px 10px 0px rgba(58, 61, 64, 0.08), 0px 1px 18px 0px rgba(58, 61, 64, 0.06);',
    '0px 4px 5px -2px rgba(58, 61, 64, 0.12), 0px 7px 10px 1px rgba(58, 61, 64, 0.08), 0px 2px 16px 1px rgba(58, 61, 64, 0.06);',
    '0px 5px 5px -3px rgba(58, 61, 64, 0.12), 0px 8px 10px 1px rgba(58, 61, 64, 0.08), 0px 3px 14px 2px rgba(58, 61, 64, 0.06);',
    '0px 5px 6px -3px rgba(58, 61, 64, 0.12), 0px 9px 12px 1px rgba(58, 61, 64, 0.08), 0px 3px 16px 2px rgba(58, 61, 64, 0.06);',
    '0px 6px 6px -3px rgba(58, 61, 64, 0.12), 0px 10px 14px 1px rgba(58, 61, 64, 0.08), 0px 4px 18px 3px rgba(58, 61, 64, 0.06);',
    '0px 6px 7px -4px rgba(58, 61, 64, 0.12), 0px 11px 15px 1px rgba(58, 61, 64, 0.08), 0px 4px 20px 3px rgba(58, 61, 64, 0.06);',
    '0px 7px 8px -4px rgba(58, 61, 64, 0.12), 0px 12px 17px 2px rgba(58, 61, 64, 0.08), 0px 5px 22px 4px rgba(58, 61, 64, 0.06);',
    '0px 7px 8px -4px rgba(58, 61, 64, 0.12), 0px 13px 19px 2px rgba(58, 61, 64, 0.08), 0px 5px 24px 4px rgba(58, 61, 64, 0.06);',
    '0px 7px 9px -4px rgba(58, 61, 64, 0.12), 0px 14px 21px 2px rgba(58, 61, 64, 0.08), 0px 5px 26px 4px rgba(58, 61, 64, 0.06);',
    '0px 8px 9px -5px rgba(58, 61, 64, 0.12), 0px 15px 22px 2px rgba(58, 61, 64, 0.08), 0px 6px 28px 5px rgba(58, 61, 64, 0.06);',
    '0px 8px 10px -5px rgba(58, 61, 64, 0.12), 0px 16px 24px 2px rgba(58, 61, 64, 0.08), 0px 6px 30px 5px rgba(58, 61, 64, 0.06);',
    '0px 8px 11px -5px rgba(58, 61, 64, 0.12), 0px 17px 26px 2px rgba(58, 61, 64, 0.08), 0px 6px 32px 5px rgba(58, 61, 64, 0.06);',
    '0px 9px 11px -5px rgba(58, 61, 64, 0.12), 0px 18px 28px 2px rgba(58, 61, 64, 0.08), 0px 7px 34px 6px rgba(58, 61, 64, 0.06);',
    '0px 9px 12px -6px rgba(58, 61, 64, 0.12), 0px 19px 29px 2px rgba(58, 61, 64, 0.08), 0px 7px 36px 6px rgba(58, 61, 64, 0.06);',
    '0px 10px 13px -6px rgba(58, 61, 64, 0.12), 0px 20px 31px 3px rgba(58, 61, 64, 0.08), 0px 8px 38px 7px rgba(58, 61, 64, 0.06);',
    '0px 10px 13px -6px rgba(58, 61, 64, 0.12), 0px 21px 33px 3px rgba(58, 61, 64, 0.08), 0px 8px 40px 7px rgba(58, 61, 64, 0.06);',
    '0px 10px 14px -6px rgba(58, 61, 64, 0.12), 0px 22px 35px 3px rgba(58, 61, 64, 0.08), 0px 8px 42px 7px rgba(58, 61, 64, 0.06);',
    '0px 11px 14px -7px rgba(58, 61, 64, 0.12), 0px 23px 36px 3px rgba(58, 61, 64, 0.08), 0px 9px 44px 8px rgba(58, 61, 64, 0.06);',
    '0px 11px 15px -7px rgba(58, 61, 64, 0.12), 0px 24px 38px 3px rgba(58, 61, 64, 0.08), 0px 9px 46px 8px rgba(58, 61, 64, 0.06);',
  ],
  typography: {
    h1: {
      fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
      fontWeight: 400,
    },
    h2: {
      fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
      fontWeight: 400,
    },
    h3: {
      fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
    },
    h4: {
      fontSize: '2rem',
      fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
    },
    h5: {
      fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
    },
    h6: {
      fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
    },
    body1: {
      fontFamily: 'Inter, Helvetica, Arial, sans-serif',
    },
    body2: {
      fontFamily: 'Inter, Helvetica, Arial, sans-serif',
    },
    subtitle1: {
      fontFamily: 'Inter, Helvetica, Arial, sans-serif',
    },
    subtitle2: {
      fontFamily: 'Inter, Helvetica, Arial, sans-serif',
    },
    overline: {
      fontFamily: 'Inter, Helvetica, Arial, sans-serif',
    },
    caption: {
      fontFamily: 'Inter, Helvetica, Arial, sans-serif',
    },
  },
  shape: {
    'borderRadius-xs': 1,
    'borderRadius-s': 2,
    'borderRadius-m': 4,
    'borderRadius-l': 6,
    'borderRadius-xl': 8,
    'borderRadius-xxl': 12,
    'borderRadius-rounded': 999,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-sizeLarge.MuiButton-containedPrimary': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-primary-contrastText)',
              background: 'var(--mui-palette-primary-darker)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-primary-contrastText)',
              background: 'var(--mui-palette-primary-darker)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-primary-contrastText)',
              background: 'var(--mui-palette-primary-dark)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-containedInherit': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: '#4F5459',
              background: '#B4B6BD',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: '#4F5459',
              background: '#B4B6BD',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: '#4F5459',
              background: '#CCCED4',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-containedInherit (white)': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              background: '#CCCED4',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              background: '#CCCED4',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-common-white_states-main)',
              background: '#F0F1F3',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-containedSecondary': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-secondary-contrast)',
              background: 'var(--mui-palette-secondary-lighter)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-secondary-contrast)',
              background: 'var(--mui-palette-secondary-lighter)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-secondary-contrast)',
              background: 'var(--mui-palette-secondary-light)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-containedError': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-error-contrastText)',
              background: 'var(--mui-palette-error-darker)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-error-contrastText)',
              background: 'var(--mui-palette-error-darker)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-error-contrastText)',
              background: 'var(--mui-palette-error-dark)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-containedWarning': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-warning-contrastText)',
              background: 'var(--mui-palette-warning-darker)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-warning-contrastText)',
              background: 'var(--mui-palette-warning-darker)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-warning-contrastText)',
              background: 'var(--mui-palette-warning-dark)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-containedInfo': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-info-contrastText)',
              background: 'var(--mui-palette-info-darker)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-info-contrastText)',
              background: 'var(--mui-palette-info-darker)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-info-contrastText)',
              background: 'var(--mui-palette-info-dark)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-containedSuccess': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-success-contrast)',
              background: 'var(--mui-palette-success-darker)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-success-contrast)',
              background: 'var(--mui-palette-success-darker)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-success-contrast)',
              background: 'var(--mui-palette-success-dark)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-containedPrimary': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-primary-contrastText)',
              background: 'var(--mui-palette-primary-darker)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-primary-contrastText)',
              background: 'var(--mui-palette-primary-darker)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-primary-contrastText)',
              background: 'var(--mui-palette-primary-dark)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-containedInherit': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: '#4F5459',
              background: '#B4B6BD',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: '#4F5459',
              background: '#B4B6BD',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: '#4F5459',
              background: '#CCCED4',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-containedInherit (white)': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              background: '#CCCED4',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              background: '#CCCED4',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-common-white_states-main)',
              background: '#F0F1F3',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-containedSecondary': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-secondary-contrast)',
              background: 'var(--mui-palette-secondary-lighter)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-secondary-contrast)',
              background: 'var(--mui-palette-secondary-lighter)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-secondary-contrast)',
              background: 'var(--mui-palette-secondary-light)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-containedError': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-error-contrastText)',
              background: 'var(--mui-palette-error-darker)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-error-contrastText)',
              background: 'var(--mui-palette-error-darker)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-error-contrastText)',
              background: 'var(--mui-palette-error-dark)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-containedWarning': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-warning-contrastText)',
              background: 'var(--mui-palette-warning-darker)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-warning-contrastText)',
              background: 'var(--mui-palette-warning-darker)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-warning-contrastText)',
              background: 'var(--mui-palette-warning-dark)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-containedInfo': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-info-contrastText)',
              background: 'var(--mui-palette-info-darker)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-info-contrastText)',
              background: 'var(--mui-palette-info-darker)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-info-contrastText)',
              background: 'var(--mui-palette-info-dark)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-containedSuccess': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-success-contrast)',
              background: 'var(--mui-palette-success-darker)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-success-contrast)',
              background: 'var(--mui-palette-success-darker)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-success-contrast)',
              background: 'var(--mui-palette-success-dark)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-containedPrimary': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 10px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-primary-contrastText)',
              background: 'var(--mui-palette-primary-darker)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-primary-contrastText)',
              background: 'var(--mui-palette-primary-darker)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-primary-contrastText)',
              background: 'var(--mui-palette-primary-dark)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-containedInherit': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 10px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: '#4F5459',
              background: '#B4B6BD',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: '#4F5459',
              background: '#B4B6BD',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: '#4F5459',
              background: '#CCCED4',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-containedInherit (white)': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 10px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              background: '#CCCED4',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              background: '#CCCED4',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-common-white_states-main)',
              background: '#F0F1F3',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-containedSecondary': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 10px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-secondary-contrast)',
              background: 'var(--mui-palette-secondary-lighter)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-secondary-contrast)',
              background: 'var(--mui-palette-secondary-lighter)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-secondary-contrast)',
              background: 'var(--mui-palette-secondary-light)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-containedError': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 10px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-error-contrastText)',
              background: 'var(--mui-palette-error-darker)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-error-contrastText)',
              background: 'var(--mui-palette-error-darker)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-error-contrastText)',
              background: 'var(--mui-palette-error-dark)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-containedWarning': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 10px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-warning-contrastText)',
              background: 'var(--mui-palette-warning-darker)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-warning-contrastText)',
              background: 'var(--mui-palette-warning-darker)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-warning-contrastText)',
              background: 'var(--mui-palette-warning-dark)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-containedInfo': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 10px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-info-contrastText)',
              background: 'var(--mui-palette-info-darker)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-info-contrastText)',
              background: 'var(--mui-palette-info-darker)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-info-contrastText)',
              background: 'var(--mui-palette-info-dark)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-containedSuccess': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 10px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-success-contrast)',
              background: 'var(--mui-palette-success-darker)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-success-contrast)',
              background: 'var(--mui-palette-success-darker)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'var(--mui-palette-action-disabledBackground)',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-success-contrast)',
              background: 'var(--mui-palette-success-dark)',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-outlinedPrimary': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-primary-main)',
              border:
                '1px solid var(--mui-palette-primary-_states-outlinedBorder)',
              background: 'var(--mui-palette-primary-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-primary-main)',
              border:
                '1px solid var(--mui-palette-primary-_states-outlinedBorder)',
              background: 'var(--mui-palette-primary-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-primary-main)',
              border:
                '1px solid var(--mui-palette-primary-_states-outlinedBorder)',
              background: 'var(--mui-palette-primary-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-outlinedInherit': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: '#4F5459',
              border: '1px solid #E0E1E5',
              background: '#E0E1E5',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: '#4F5459',
              border: '1px solid #E0E1E5',
              background: '#E0E1E5',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: '#4F5459',
              border: '1px solid #E0E1E5',
              background: '#F0F1F3',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-outlinedInherit (white)': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              border: '1px solid var(--mui-palette-common-white_states-main)',
              background: 'none',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              border: '1px solid var(--mui-palette-common-white_states-main)',
              background: 'none',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-common-white_states-main)',
              border: '1px solid var(--mui-palette-common-white_states-main)',
              background: 'var(--mui-palette-action-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-outlinedSecondary': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-secondary-dark)',
              border:
                '1px solid var(--mui-palette-secondary-_states-outlinedBorder)',
              background: 'var(--mui-palette-secondary-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-secondary-dark)',
              border:
                '1px solid var(--mui-palette-secondary-_states-outlinedBorder)',
              background: 'var(--mui-palette-secondary-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-secondary-dark)',
              border:
                '1px solid var(--mui-palette-secondary-_states-outlinedBorder)',
              background: 'var(--mui-palette-secondary-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-outlinedError': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-error-main)',
              border:
                '1px solid var(--mui-palette-error-_states-outlinedBorder)',
              background: 'var(--mui-palette-error-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-error-main)',
              border:
                '1px solid var(--mui-palette-error-_states-outlinedBorder)',
              background: 'var(--mui-palette-error-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-error-main)',
              border:
                '1px solid var(--mui-palette-error-_states-outlinedBorder)',
              background: 'var(--mui-palette-error-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-outlinedWarning': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
              border:
                '1px solid var(--mui-palette-warning-_states-outlinedBorder)',
              background: 'var(--mui-palette-warning-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
              border:
                '1px solid var(--mui-palette-warning-_states-outlinedBorder)',
              background: 'var(--mui-palette-warning-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-warning-main)',
              border:
                '1px solid var(--mui-palette-warning-_states-outlinedBorder)',
              background: 'var(--mui-palette-warning-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-outlinedInfo': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-info-main)',
              border:
                '1px solid var(--mui-palette-info-_states-outlinedBorder)',
              background: 'var(--mui-palette-info-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-info-main)',
              border:
                '1px solid var(--mui-palette-info-_states-outlinedBorder)',
              background: 'var(--mui-palette-info-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-info-main)',
              border:
                '1px solid var(--mui-palette-info-_states-outlinedBorder)',
              background: 'var(--mui-palette-info-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-outlinedSuccess': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-success-main)',
              border:
                '1px solid var(--mui-palette-success-_states-outlinedBorder)',
              background: 'var(--mui-palette-success-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-success-main)',
              border:
                '1px solid var(--mui-palette-success-_states-outlinedBorder)',
              background: 'var(--mui-palette-success-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-success-main)',
              border:
                '1px solid var(--mui-palette-success-_states-outlinedBorder)',
              background: 'var(--mui-palette-success-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-outlinedPrimary': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-primary-main)',
              border:
                '1px solid var(--mui-palette-primary-_states-outlinedBorder)',
              background: 'var(--mui-palette-primary-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-primary-main)',
              border:
                '1px solid var(--mui-palette-primary-_states-outlinedBorder)',
              background: 'var(--mui-palette-primary-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-primary-main)',
              border:
                '1px solid var(--mui-palette-primary-_states-outlinedBorder)',
              background: 'var(--mui-palette-primary-_states-hover)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-outlinedInherit': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: '#4F5459',
              border: '1px solid #E0E1E5',
              background: '#E0E1E5',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: '#4F5459',
              border: '1px solid #E0E1E5',
              background: '#E0E1E5',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: '#4F5459',
              border: '1px solid #E0E1E5',
              background: '#F0F1F3',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-outlinedInherit (white)': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              border: '1px solid var(--mui-palette-common-white_states-main)',
              background: 'none',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              border: '1px solid var(--mui-palette-common-white_states-main)',
              background: 'none',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-common-white_states-main)',
              border: '1px solid var(--mui-palette-common-white_states-main)',
              background: 'var(--mui-palette-action-hover)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-outlinedSecondary': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-secondary-dark)',
              border:
                '1px solid var(--mui-palette-secondary-_states-outlinedBorder)',
              background: 'var(--mui-palette-secondary-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-secondary-dark)',
              border:
                '1px solid var(--mui-palette-secondary-_states-outlinedBorder)',
              background: 'var(--mui-palette-secondary-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-secondary-dark)',
              border:
                '1px solid var(--mui-palette-secondary-_states-outlinedBorder)',
              background: 'var(--mui-palette-secondary-_states-hover)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-outlinedError': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-error-main)',
              border:
                '1px solid var(--mui-palette-error-_states-outlinedBorder)',
              background: 'var(--mui-palette-error-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-error-main)',
              border:
                '1px solid var(--mui-palette-error-_states-outlinedBorder)',
              background: 'var(--mui-palette-error-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-error-main)',
              border:
                '1px solid var(--mui-palette-error-_states-outlinedBorder)',
              background: 'var(--mui-palette-error-_states-hover)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-outlinedWarning': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
              border:
                '1px solid var(--mui-palette-warning-_states-outlinedBorder)',
              background: 'var(--mui-palette-warning-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
              border:
                '1px solid var(--mui-palette-warning-_states-outlinedBorder)',
              background: 'var(--mui-palette-warning-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-warning-main)',
              border:
                '1px solid var(--mui-palette-warning-_states-outlinedBorder)',
              background: 'var(--mui-palette-warning-_states-hover)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-outlinedInfo': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-info-main)',
              border:
                '1px solid var(--mui-palette-info-_states-outlinedBorder)',
              background: 'var(--mui-palette-info-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-info-main)',
              border:
                '1px solid var(--mui-palette-info-_states-outlinedBorder)',
              background: 'var(--mui-palette-info-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-info-main)',
              border:
                '1px solid var(--mui-palette-info-_states-outlinedBorder)',
              background: 'var(--mui-palette-info-_states-hover)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-outlinedSuccess': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-success-main)',
              border:
                '1px solid var(--mui-palette-success-_states-outlinedBorder)',
              background: 'var(--mui-palette-success-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-success-main)',
              border:
                '1px solid var(--mui-palette-success-_states-outlinedBorder)',
              background: 'var(--mui-palette-success-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-success-main)',
              border:
                '1px solid var(--mui-palette-success-_states-outlinedBorder)',
              background: 'var(--mui-palette-success-_states-hover)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-outlinedPrimary': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 10px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-primary-main)',
              border:
                '1px solid var(--mui-palette-primary-_states-outlinedBorder)',
              background: 'var(--mui-palette-primary-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-primary-main)',
              border:
                '1px solid var(--mui-palette-primary-_states-outlinedBorder)',
              background: 'var(--mui-palette-primary-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-primary-main)',
              border:
                '1px solid var(--mui-palette-primary-_states-outlinedBorder)',
              background: 'var(--mui-palette-primary-_states-hover)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-outlinedInherit': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 10px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: '#4F5459',
              border: '1px solid #E0E1E5',
              background: '#E0E1E5',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: '#4F5459',
              border: '1px solid #E0E1E5',
              background: '#E0E1E5',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: '#4F5459',
              border: '1px solid #E0E1E5',
              background: '#F0F1F3',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-outlinedInherit (white)': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 10px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              border: '1px solid var(--mui-palette-common-white_states-main)',
              background: 'none',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              border: '1px solid var(--mui-palette-common-white_states-main)',
              background: 'none',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-common-white_states-main)',
              border: '1px solid var(--mui-palette-common-white_states-main)',
              background: 'var(--mui-palette-action-hover)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-outlinedSecondary': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 10px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-secondary-dark)',
              border:
                '1px solid var(--mui-palette-secondary-_states-outlinedBorder)',
              background: 'var(--mui-palette-secondary-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-secondary-dark)',
              border:
                '1px solid var(--mui-palette-secondary-_states-outlinedBorder)',
              background: 'var(--mui-palette-secondary-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-secondary-dark)',
              border:
                '1px solid var(--mui-palette-secondary-_states-outlinedBorder)',
              background: 'var(--mui-palette-secondary-_states-hover)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-outlinedError': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 10px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-error-main)',
              border:
                '1px solid var(--mui-palette-error-_states-outlinedBorder)',
              background: 'var(--mui-palette-error-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-error-main)',
              border:
                '1px solid var(--mui-palette-error-_states-outlinedBorder)',
              background: 'var(--mui-palette-error-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-error-main)',
              border:
                '1px solid var(--mui-palette-error-_states-outlinedBorder)',
              background: 'var(--mui-palette-error-_states-hover)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-outlinedWarning': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 10px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
              border:
                '1px solid var(--mui-palette-warning-_states-outlinedBorder)',
              background: 'var(--mui-palette-warning-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
              border:
                '1px solid var(--mui-palette-warning-_states-outlinedBorder)',
              background: 'var(--mui-palette-warning-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-warning-main)',
              border:
                '1px solid var(--mui-palette-warning-_states-outlinedBorder)',
              background: 'var(--mui-palette-warning-_states-hover)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-outlinedInfo': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 10px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-info-main)',
              border:
                '1px solid var(--mui-palette-info-_states-outlinedBorder)',
              background: 'var(--mui-palette-info-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-info-main)',
              border:
                '1px solid var(--mui-palette-info-_states-outlinedBorder)',
              background: 'var(--mui-palette-info-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-info-main)',
              border:
                '1px solid var(--mui-palette-info-_states-outlinedBorder)',
              background: 'var(--mui-palette-info-_states-hover)',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-outlinedSuccess': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 10px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-success-main)',
              border:
                '1px solid var(--mui-palette-success-_states-outlinedBorder)',
              background: 'var(--mui-palette-success-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-success-main)',
              border:
                '1px solid var(--mui-palette-success-_states-outlinedBorder)',
              background: 'var(--mui-palette-success-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              border: '1px solid var(--mui-palette-action-disabledBackground)',
              background: 'none',
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-success-main)',
              border:
                '1px solid var(--mui-palette-success-_states-outlinedBorder)',
              background: 'var(--mui-palette-success-_states-hover)',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-textPrimary': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-primary-main)',
              background: 'var(--mui-palette-primary-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-primary-main)',
              background: 'var(--mui-palette-primary-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-primary-main)',
              background: 'var(--mui-palette-primary-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-textInherit': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: '#4F5459',
              background: '#E0E1E5',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: '#4F5459',
              background: '#E0E1E5',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: '#4F5459',
              background: '#F0F1F3',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-textInherit (white)': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              background: 'none',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              background: 'none',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-common-white_states-main)',
              background: 'var(--mui-palette-action-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-textSecondary': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-secondary-dark)',
              background: 'var(--mui-palette-secondary-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-secondary-dark)',
              background: 'var(--mui-palette-secondary-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-secondary-dark)',
              background: 'var(--mui-palette-secondary-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-textError': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-error-main)',
              background: 'var(--mui-palette-error-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-error-main)',
              background: 'var(--mui-palette-error-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-error-main)',
              background: 'var(--mui-palette-error-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-textWarning': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
              background: 'var(--mui-palette-warning-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
              background: 'var(--mui-palette-warning-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-warning-main)',
              background: 'var(--mui-palette-warning-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-textInfo': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-info-main)',
              background: 'var(--mui-palette-info-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-info-main)',
              background: 'var(--mui-palette-info-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-info-main)',
              background: 'var(--mui-palette-info-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeMedium.MuiButton-textSuccess': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '24px /* 171.429% */',
            letterSpacing: '0.4px',
            textTransform: 'none',
            height: '36px',
            padding: '6px 16px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-success-main)',
              background: 'var(--mui-palette-success-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-success-main)',
              background: 'var(--mui-palette-success-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-success-main)',
              background: 'var(--mui-palette-success-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-textPrimary': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-primary-main)',
              background: 'var(--mui-palette-primary-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-primary-main)',
              background: 'var(--mui-palette-primary-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-primary-main)',
              background: 'var(--mui-palette-primary-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-textInherit': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: '#4F5459',
              background: '#E0E1E5',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: '#4F5459',
              background: '#E0E1E5',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: '#4F5459',
              background: '#F0F1F3',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-textInherit (white)': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              background: 'none',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              background: 'none',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-common-white_states-main)',
              background: 'var(--mui-palette-action-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-textSecondary': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-secondary-dark)',
              background: 'var(--mui-palette-secondary-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-secondary-dark)',
              background: 'var(--mui-palette-secondary-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-secondary-dark)',
              background: 'var(--mui-palette-secondary-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-textError': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-error-main)',
              background: 'var(--mui-palette-error-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-error-main)',
              background: 'var(--mui-palette-error-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-error-main)',
              background: 'var(--mui-palette-error-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-textWarning': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
              background: 'var(--mui-palette-warning-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
              background: 'var(--mui-palette-warning-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-warning-main)',
              background: 'var(--mui-palette-warning-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-textInfo': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-info-main)',
              background: 'var(--mui-palette-info-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-info-main)',
              background: 'var(--mui-palette-info-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-info-main)',
              background: 'var(--mui-palette-info-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeLarge.MuiButton-textSuccess': {
            fontFeatureSettings: "'liga' off, 'clig' off",
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '26px /* 162.5% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '44px',
            padding: '8px 20px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-success-main)',
              background: 'var(--mui-palette-success-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-success-main)',
              background: 'var(--mui-palette-success-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-success-main)',
              background: 'var(--mui-palette-success-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-textPrimary': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 5px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-primary-main)',
              background: 'var(--mui-palette-primary-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-primary-main)',
              background: 'var(--mui-palette-primary-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-primary-main)',
              background: 'var(--mui-palette-primary-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-textInherit': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 5px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: '#4F5459',
              background: '#E0E1E5',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: '#4F5459',
              background: '#E0E1E5',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: '#4F5459',
              background: '#F0F1F3',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-textInherit (white)': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 5px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              background: 'none',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-common-white_states-main)',
              background: 'none',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-common-white_states-main)',
              background: 'var(--mui-palette-action-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-textSecondary': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 5px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-secondary-dark)',
              background: 'var(--mui-palette-secondary-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-secondary-dark)',
              background: 'var(--mui-palette-secondary-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-secondary-dark)',
              background: 'var(--mui-palette-secondary-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-textError': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 5px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-error-main)',
              background: 'var(--mui-palette-error-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-error-main)',
              background: 'var(--mui-palette-error-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-error-main)',
              background: 'var(--mui-palette-error-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-textWarning': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 5px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
              background: 'var(--mui-palette-warning-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-warning-main)',
              background: 'var(--mui-palette-warning-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-warning-main)',
              background: 'var(--mui-palette-warning-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-textInfo': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 5px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-info-main)',
              background: 'var(--mui-palette-info-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-info-main)',
              background: 'var(--mui-palette-info-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-info-main)',
              background: 'var(--mui-palette-info-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
          '&.MuiButton-sizeSmall.MuiButton-textSuccess': {
            fontFamily: 'Gabarito, Helvetica, Arial, sans-serif',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '22px /* 183.333% */',
            letterSpacing: '0.46px',
            textTransform: 'none',
            height: '28px',
            padding: '4px 5px 4px 12px',
            borderRadius: 'var(--mui-shape-borderRadius-xl)',
            opacity: '1',
            boxShadow: 'none',
            '&:not(.Mui-disabled):not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-success-main)',
              background: 'var(--mui-palette-success-_states-selected)',
            },
            '&:not(.Mui-disabled).Mui-focusVisible:not(:hover)': {
              color: 'var(--mui-palette-success-main)',
              background: 'var(--mui-palette-success-_states-selected)',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            '&:not(.Mui-disabled):not(.Mui-focusVisible):hover': {
              color: 'var(--mui-palette-success-main)',
              background: 'var(--mui-palette-success-_states-hover)',
            },
            '&.Mui-disabled:not(.Mui-focusVisible):not(:hover)': {
              color: 'var(--mui-palette-action-disabled)',
              background: 'none',
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          '&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorDefault)': {
            width: '48px',
            height: '24px',
            '& .MuiSwitch-switchBase': {
              width: '16px',
              height: '16px',
              boxShadow: 'var(--mui-shadows-1)',
              padding: '0',
              opacity: '1',
              borderRadius: '50%',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: 'var(--mui-palette-primary-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-primary-contrastText)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: '#CCCED4',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: 'var(--mui-palette-primary-dark)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-primary-contrastText)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: 'var(--mui-palette-primary-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-primary-contrastText)',
                },
              },
          },
          '&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorPrimary)': {
            width: '48px',
            height: '24px',
            '& .MuiSwitch-switchBase': {
              width: '16px',
              height: '16px',
              padding: '0',
              opacity: '1',
              borderRadius: '50%',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                  boxShadow: 'var(--mui-shadows-1)',
                },
              },
            '&:not(:has(.Mui-checked)):has(.Mui-disabled):not(:has(.Mui-focusVisible))':
              {
                background: '#E0E1E5',
                '& .MuiSwitch-switchBase': {
                  background: '#CCCED4',
                  boxShadow: 'none',
                },
              },
            '&:has(.Mui-checked):has(.Mui-disabled):not(:has(.Mui-focusVisible))':
              {
                background: '#CCCED4',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-primary-contrastText)',
                  boxShadow: 'var(--mui-shadows-1)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: '#CCCED4',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                  boxShadow: 'var(--mui-shadows-1)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                  boxShadow: 'var(--mui-shadows-1)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: 'var(--mui-palette-primary-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-primary-contrastText)',
                  boxShadow: 'var(--mui-shadows-1)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: 'var(--mui-palette-primary-dark)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-primary-contrastText)',
                  boxShadow: 'var(--mui-shadows-1)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: 'var(--mui-palette-primary-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-primary-contrastText)',
                  boxShadow: 'var(--mui-shadows-1)',
                },
              },
          },
          '&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorError)': {
            width: '48px',
            height: '24px',
            '& .MuiSwitch-switchBase': {
              width: '16px',
              height: '16px',
              boxShadow: 'var(--mui-shadows-1)',
              padding: '0',
              opacity: '1',
              borderRadius: '50%',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: 'var(--mui-palette-error-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-error-contrastText)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: '#CCCED4',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: 'var(--mui-palette-error-dark)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-error-contrastText)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: 'var(--mui-palette-error-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-error-contrastText)',
                },
              },
          },
          '&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorWarning)': {
            width: '48px',
            height: '24px',
            '& .MuiSwitch-switchBase': {
              width: '16px',
              height: '16px',
              boxShadow: 'var(--mui-shadows-1)',
              padding: '0',
              opacity: '1',
              borderRadius: '50%',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: 'var(--mui-palette-warning-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-warning-contrastText)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: '#CCCED4',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: 'var(--mui-palette-warning-dark)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-warning-contrastText)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: 'var(--mui-palette-warning-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-warning-contrastText)',
                },
              },
          },
          '&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorInfo)': {
            width: '48px',
            height: '24px',
            '& .MuiSwitch-switchBase': {
              width: '16px',
              height: '16px',
              boxShadow: 'var(--mui-shadows-1)',
              padding: '0',
              opacity: '1',
              borderRadius: '50%',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: 'var(--mui-palette-info-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-info-contrastText)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: '#CCCED4',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: 'var(--mui-palette-info-dark)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-info-contrastText)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: 'var(--mui-palette-info-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-info-contrastText)',
                },
              },
          },
          '&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorSuccess)': {
            width: '48px',
            height: '24px',
            '& .MuiSwitch-switchBase': {
              width: '16px',
              height: '16px',
              boxShadow: 'var(--mui-shadows-1)',
              padding: '0',
              opacity: '1',
              borderRadius: '50%',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: 'var(--mui-palette-success-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-success-contrast)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: '#CCCED4',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: 'var(--mui-palette-success-dark)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-success-contrast)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: 'var(--mui-palette-success-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-success-contrast)',
                },
              },
          },
          '&.MuiSwitch-sizeMedium:has(.MuiSwitch-colorSecondary)': {
            width: '48px',
            height: '24px',
            '& .MuiSwitch-switchBase': {
              width: '16px',
              height: '16px',
              boxShadow: 'var(--mui-shadows-1)',
              padding: '0',
              opacity: '1',
              borderRadius: '50%',
            },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: 'var(--mui-palette-secondary-dark)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-secondary-contrast)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: 'var(--mui-palette-secondary-light)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-secondary-contrast)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: 'var(--mui-palette-secondary-dark)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-secondary-contrast)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: '#CCCED4',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
          },
          '&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorDefault)': {
            width: '36px',
            height: '18px',
            '& .MuiSwitch-switchBase': {
              width: '12px',
              height: '12px',
              boxShadow: 'var(--mui-shadows-1)',
              padding: '0',
              opacity: '1',
              borderRadius: '50%',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: 'var(--mui-palette-primary-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-primary-contrastText)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: '#CCCED4',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: 'var(--mui-palette-primary-dark)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-primary-contrastText)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: 'var(--mui-palette-primary-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-primary-contrastText)',
                },
              },
          },
          '&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorPrimary)': {
            width: '36px',
            height: '18px',
            '& .MuiSwitch-switchBase': {
              width: '12px',
              height: '12px',
              padding: '0',
              opacity: '1',
              borderRadius: '50%',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                  boxShadow: 'var(--mui-shadows-1)',
                },
              },
            '&:not(:has(.Mui-checked)):has(.Mui-disabled):not(:has(.Mui-focusVisible))':
              {
                background: '#E0E1E5',
                '& .MuiSwitch-switchBase': {
                  background: '#CCCED4',
                  boxShadow: 'none',
                },
              },
            '&:has(.Mui-checked):has(.Mui-disabled):not(:has(.Mui-focusVisible))':
              {
                background: '#CCCED4',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-primary-contrastText)',
                  boxShadow: 'var(--mui-shadows-1)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: '#CCCED4',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                  boxShadow: 'var(--mui-shadows-1)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                  boxShadow: 'var(--mui-shadows-1)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: 'var(--mui-palette-primary-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-primary-contrastText)',
                  boxShadow: 'var(--mui-shadows-1)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: 'var(--mui-palette-primary-dark)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-primary-contrastText)',
                  boxShadow: 'var(--mui-shadows-1)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: 'var(--mui-palette-primary-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-primary-contrastText)',
                  boxShadow: 'var(--mui-shadows-1)',
                },
              },
          },
          '&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorError)': {
            width: '36px',
            height: '18px',
            '& .MuiSwitch-switchBase': {
              width: '12px',
              height: '12px',
              boxShadow: 'var(--mui-shadows-1)',
              padding: '0',
              opacity: '1',
              borderRadius: '50%',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: 'var(--mui-palette-error-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-error-contrastText)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: '#CCCED4',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: 'var(--mui-palette-error-dark)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-error-contrastText)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: 'var(--mui-palette-error-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-error-contrastText)',
                },
              },
          },
          '&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorWarning)': {
            width: '36px',
            height: '18px',
            '& .MuiSwitch-switchBase': {
              width: '12px',
              height: '12px',
              boxShadow: 'var(--mui-shadows-1)',
              padding: '0',
              opacity: '1',
              borderRadius: '50%',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: 'var(--mui-palette-warning-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-warning-contrastText)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: '#CCCED4',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: 'var(--mui-palette-warning-dark)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-warning-contrastText)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: 'var(--mui-palette-warning-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-warning-contrastText)',
                },
              },
          },
          '&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorInfo)': {
            width: '36px',
            height: '18px',
            '& .MuiSwitch-switchBase': {
              width: '12px',
              height: '12px',
              boxShadow: 'var(--mui-shadows-1)',
              padding: '0',
              opacity: '1',
              borderRadius: '50%',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: 'var(--mui-palette-info-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-info-contrastText)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: '#CCCED4',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: 'var(--mui-palette-info-dark)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-info-contrastText)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: 'var(--mui-palette-info-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-info-contrastText)',
                },
              },
          },
          '&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorSuccess)': {
            width: '36px',
            height: '18px',
            '& .MuiSwitch-switchBase': {
              width: '12px',
              height: '12px',
              boxShadow: 'var(--mui-shadows-1)',
              padding: '0',
              opacity: '1',
              borderRadius: '50%',
            },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: 'var(--mui-palette-success-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-success-contrast)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: '#CCCED4',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: 'var(--mui-palette-success-dark)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-success-contrast)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: 'var(--mui-palette-success-darker)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-success-contrast)',
                },
              },
          },
          '&.MuiSwitch-sizeSmall:has(.MuiSwitch-colorSecondary)': {
            width: '36px',
            height: '18px',
            '& .MuiSwitch-switchBase': {
              width: '12px',
              height: '12px',
              boxShadow: 'var(--mui-shadows-1)',
              padding: '0',
              opacity: '1',
              borderRadius: '50%',
            },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: 'var(--mui-palette-secondary-dark)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-secondary-contrast)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: 'var(--mui-palette-secondary-light)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-secondary-contrast)',
                },
              },
            '&:has(.Mui-checked):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: 'var(--mui-palette-secondary-dark)',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-secondary-contrast)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible))':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):not(:has(.Mui-focusVisible)):hover':
              {
                background: '#CCCED4',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
            '&:not(:has(.Mui-checked)):not(:has(.Mui-disabled)):has(.Mui-focusVisible)':
              {
                background: '#B4B6BD',
                '& .MuiSwitch-switchBase': {
                  background: 'var(--mui-palette-common-white_states-main)',
                },
              },
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          border: '1px solid var(--mui-palette-divider)',
          backgroundColor: 'var(--mui-palette-common-white_states-main)',
          height: topBarHeight,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          width: 48,
          height: 48,
          svg: {
            fill: 'var(--mui-palette-background-bombay)',
          },
          '&:hover': {
            svg: {
              fill: 'var(--mui-palette-primary-main)',
            },
          },
          '@media (prefers-color-scheme: dark)': {
            '&:hover': {
              svg: {
                fill: 'var(--mui-palette-primary-darker)',
              },
            },
          },
        },
      },
    },
  },
});

export default theme;
