import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import defaultState from 'store/projects/defaultState';
import {
  createProject,
  editProject,
  exportProject,
  fetchProjects,
} from 'store/projects/thunks';

export const projects = createSlice({
  name: 'projects',
  initialState: defaultState,
  reducers: {
    setCurrentProjectById: (
      draft,
      action: PayloadAction<{ projectId: string | null }>,
    ) => {
      if (action.payload.projectId === draft.currentProject?.id) {
        return;
      }

      const chosenProject = draft.projects?.find(
        (project) => project.id === action.payload.projectId,
      );

      draft.currentProject = chosenProject || null;

      if (!chosenProject?.id) {
        return;
      }

      localStorage.setItem('project', chosenProject.id);
    },

    resetProjectsState: (draft) => {
      draft.projects = defaultState.projects;
      draft.currentProject = defaultState.currentProject;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjects.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(fetchProjects.fulfilled, (draft, action) => {
      const { 'hydra:member': projects } = action.payload;
      draft.projects = projects;

      const projectId = localStorage.getItem('project');

      const chosenProject = draft.projects.find(
        (project) => project.id === projectId,
      );

      draft.currentProject = chosenProject || null;

      draft.isRequestPending = false;
    });
    builder.addCase(fetchProjects.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(createProject.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(createProject.fulfilled, (draft, action) => {
      const newProject = action.payload;

      if (draft.projects) {
        draft.projects.push(newProject);
      } else {
        draft.projects = [newProject];
      }

      draft.isRequestPending = false;
    });
    builder.addCase(createProject.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(editProject.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(editProject.fulfilled, (draft, action) => {
      if (!draft?.projects) {
        return;
      }
      const index = draft.projects.findIndex(
        (project) => project.id === action.payload.id,
      );
      if (index >= 0) {
        draft.projects[index] = action.payload;
      }
      if (action.payload.id === draft.currentProject?.id) {
        draft.currentProject = action.payload;
      }
      draft.isRequestPending = false;
    });
    builder.addCase(editProject.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(exportProject.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(exportProject.fulfilled, (draft, action) => {
      const { id, blob } = action.payload;

      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', `project_${id}.pdf`);

      document.body.appendChild(link);

      link.click();
      link.remove();

      draft.isRequestPending = false;
    });
    builder.addCase(exportProject.rejected, (draft) => {
      draft.isRequestPending = false;
    });
  },
});

export const { setCurrentProjectById, resetProjectsState } = projects.actions;

export default projects.reducer;
