import React from 'react';
import { Link, List, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

const getMuiComponents = () => {
  const mappingTags = [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'li',
    'ol',
    'ul',
    'a',
  ];

  const tagToVariant: { [key: string]: string } = {
    p: 'body1',
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    li: 'body1',
    a: 'button',
  };

  const components: {
    [key: string]: ({
      node,
      ...props
    }: {
      node: React.ReactNode;
    }) => React.ReactNode;
  } = {};

  mappingTags.forEach((tag) => {
    components[tag] = ({ node, ...props }) => {
      switch (tag) {
        case 'ol':
          return (
            <List
              component="ol"
              sx={{ listStyle: 'decimal', pl: 3.5, py: 0 }}
              {...props}
            />
          );
        case 'ul':
          return (
            <List
              component="ul"
              sx={{ listStyle: 'disc', pl: 3.5, py: 0 }}
              {...props}
            />
          );
        case 'li':
          return (
            <Typography
              component="li"
              variant={tagToVariant[tag] as Variant}
              {...props}
            />
          );
        case 'a':
          return (
            <Link {...props}>
              <Typography variant={tagToVariant[tag] as Variant} {...props} />
            </Link>
          );
        default:
          return (
            <Typography variant={tagToVariant[tag] as Variant} {...props} />
          );
      }
    };
  });

  return components;
};

export default getMuiComponents;
