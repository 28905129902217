import { showNotification } from 'store/notifications/notifiactions';
import { NotificationType } from 'store/notifications/interfaces';
import { useAppDispatch } from 'store/hooks';
import { ApiErrorResponse } from 'helpers/interfaces';
import { t } from 'i18next';
import isApiErrorResponse from 'helpers/isApiErrorResponse';

const useDisplayNotification = () => {
  const dispatch = useAppDispatch();

  const displayErrorNotification = (error?: unknown) => {
    if (isApiErrorResponse(error)) {
      dispatch(
        showNotification({
          type: NotificationType.error,
          message:
            (error as ApiErrorResponse)?.title ||
            (error as ApiErrorResponse)?.detail ||
            t('an_error_occurred'),
        }),
      );
      return;
    }

    dispatch(
      showNotification({
        type: NotificationType.error,
        message:
          (error as { message: string })?.message || t('an_error_occurred'),
      }),
    );
  };

  const displaySuccessNotification = (message?: string) => {
    dispatch(
      showNotification({
        type: NotificationType.success,
        message: message || t('success'),
      }),
    );
  };
  return { displayErrorNotification, displaySuccessNotification };
};

export default useDisplayNotification;
