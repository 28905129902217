import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

function AppLoader() {
  return (
    <Backdrop
      sx={(theme) => ({
        bgcolor: 'background.default',
        zIndex: theme.zIndex.drawer + 1,
      })}
      open={true}
    >
      <CircularProgress color="primary" size={50} />
    </Backdrop>
  );
}

export default AppLoader;
