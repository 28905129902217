export interface AppNotificationState {
  type?: NotificationType;
  message: string;
  isOpen: boolean;
}

export interface AppNotificationPayload {
  type?: NotificationType;
  message: string;
}

export enum NotificationType {
  error = 'error',
  info = 'info',
  warning = 'warning',
  success = 'success',
}
