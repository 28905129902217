import { createSlice } from '@reduxjs/toolkit';
import defaultState from 'store/branches/defaultState';
import {
  createBranch,
  deleteBranch,
  editBranch,
  fetchBranches,
} from 'store/branches/thunks';

export const branches = createSlice({
  name: 'branches',
  initialState: defaultState,
  reducers: {
    resetBranchesState: (draft) => {
      draft.branches = defaultState.branches;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBranches.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(fetchBranches.fulfilled, (draft, action) => {
      const { 'hydra:member': branches } = action.payload;
      draft.branches = branches;
      draft.isRequestPending = false;
    });
    builder.addCase(fetchBranches.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(createBranch.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(createBranch.fulfilled, (draft, action) => {
      if (!draft?.branches) {
        return;
      }
      draft.branches.push(action.payload);
      draft.isRequestPending = false;
    });
    builder.addCase(createBranch.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(editBranch.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(editBranch.fulfilled, (draft, action) => {
      if (!draft?.branches) {
        return;
      }
      const index = draft.branches.findIndex(
        (branch) => branch.id === action.payload.id,
      );
      if (index >= 0) {
        draft.branches[index] = action.payload;
      }
      draft.isRequestPending = false;
    });
    builder.addCase(editBranch.rejected, (draft) => {
      draft.isRequestPending = false;
    });

    builder.addCase(deleteBranch.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(deleteBranch.fulfilled, (draft, action) => {
      if (!draft?.branches) {
        return;
      }
      const index = draft.branches.findIndex(
        (branch) => branch.id === action.payload,
      );
      if (index >= 0) {
        draft.branches.splice(index, 1);
      }
      draft.isRequestPending = false;
    });
    builder.addCase(deleteBranch.rejected, (draft) => {
      draft.isRequestPending = false;
    });
  },
});

export const { resetBranchesState } = branches.actions;

export default branches.reducer;
