import { FieldPath, FieldValues, UseFormSetError } from 'react-hook-form';
import isApiErrorResponse from 'helpers/isApiErrorResponse';

function mapViolationsToFormErrors<T extends FieldValues>(
  error: unknown,
  setError: UseFormSetError<T>,
): void {
  if (!isApiErrorResponse(error)) {
    return;
  }

  error?.violations?.forEach((violation) => {
    setError(violation.propertyPath as FieldPath<T>, {
      message: violation.message,
    });
  });
}

export default mapViolationsToFormErrors;
