import React, { ReactNode } from 'react';
import { SxProps } from '@mui/material';
import { sideSectionWidth } from 'components/Layout/utils/consts';
import RoundedBox from 'common/RoundedBox/RoundedBox';

interface SideSectionProps {
  children?: ReactNode;
  sx?: SxProps;
}

function SideSection({ children, sx }: SideSectionProps) {
  return (
    <RoundedBox
      sx={{
        width: { xs: '100%', sm: sideSectionWidth },
        maxWidth: sideSectionWidth,
        height: '100%',
        ...sx,
      }}
    >
      {children}
    </RoundedBox>
  );
}

export default SideSection;
