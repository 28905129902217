import React, { useEffect, useState } from 'react';
import Layout from 'components/Layout/Layout';
import Grid from '@mui/material/Grid2';
import ArticleDialog from 'components/Dialogs/ArticleDialog/ArticleDialog';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchArticles } from 'store/articles/thunks';
import { currentProject } from 'store/projects/selectors';
import { currentOrganization } from 'store/organizations/selectors';
import { isAuthorized } from 'store/authorization/selectors';
import { Article } from 'store/articles/interfaces';
import RoundedBox from 'common/RoundedBox/RoundedBox';
import ControlsSection from 'pages/Articles/ControlsSection/ControlsSection';
import SideSectionContent from 'pages/Articles/SideSectionContent/SideSectionContent';

function Articles() {
  const isUserAuthorized = useAppSelector(isAuthorized);
  const project = useAppSelector(currentProject);
  const organization = useAppSelector(currentOrganization);
  const dispatch = useAppDispatch();

  const [isArticleDialogOpen, setIsArticleDialogOpen] = useState(false);
  const [articleToEdit, setArticleToEdit] = useState<Article | null>(null);

  useEffect(() => {
    if (!organization || !project) {
      return;
    }

    if (project?.id && isUserAuthorized) {
      dispatch(fetchArticles({ project: project.id }));
    }
  }, [organization, isUserAuthorized, dispatch, project]);

  const handleArticleDialogClose = () => {
    setIsArticleDialogOpen(false);
    setArticleToEdit(null);
  };

  return (
    <>
      <Layout
        sideSection={
          <SideSectionContent setIsArticleDialogOpen={setIsArticleDialogOpen} />
        }
      >
        <Grid container direction="column" spacing={1} sx={{ height: '100%' }}>
          <Grid size={12} sx={{ width: '100%' }}>
            <ControlsSection />
          </Grid>
          <Grid container sx={{ flexGrow: 1 }}>
            <Grid
              size={{ xs: 12, md: 6 }}
              sx={{ display: 'flex', flexGrow: 1 }}
            >
              <RoundedBox sx={{ p: 1, flexGrow: 1 }}></RoundedBox>
            </Grid>
            <Grid
              size={{ xs: 12, md: 6 }}
              sx={{ display: 'flex', flexGrow: 1 }}
            >
              <RoundedBox sx={{ p: 1, flexGrow: 1 }}></RoundedBox>
            </Grid>
          </Grid>
        </Grid>
      </Layout>
      <ArticleDialog
        isDialogOpen={isArticleDialogOpen}
        handleDialogClose={handleArticleDialogClose}
        articleToEdit={articleToEdit}
      />
    </>
  );
}

export default Articles;
