import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getDefaultHeaders,
  getDefaultUpdateHeaders,
} from 'store/utils/getDefaultHeaders';
import { ApiFilters, ApiResponse } from 'helpers/interfaces';
import buildQueryString from 'store/utils/buildQueryString';
import {
  Branch,
  CreateBranchPayload,
  DeleteBranchPayload,
  EditBranchPayload,
} from 'store/branches/interfaces';

export const fetchBranches = createAsyncThunk(
  'branches/fetchBranches',
  async (filters: ApiFilters<Branch>, thunkAPI) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/branches${buildQueryString(filters)}`,
      {
        headers: getDefaultHeaders(),
      },
    );

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return thunkAPI.fulfillWithValue(data as ApiResponse<Branch>);
  },
);

export const createBranch = createAsyncThunk(
  'branches/createBranch',
  async (payload: CreateBranchPayload, thunkAPI) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/branches`, {
      method: 'POST',
      headers: getDefaultHeaders(),
      body: JSON.stringify({
        ...payload,
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return thunkAPI.fulfillWithValue(data as Branch);
  },
);

export const editBranch = createAsyncThunk(
  'branches/editABranch',
  async (payload: EditBranchPayload, thunkAPI) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/branches/${payload.id}`,
      {
        method: 'PATCH',
        headers: getDefaultUpdateHeaders(),
        body: JSON.stringify({
          ...payload,
        }),
      },
    );

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return thunkAPI.fulfillWithValue(data as Branch);
  },
);

export const deleteBranch = createAsyncThunk(
  'branches/deleteBranch',
  async (payload: DeleteBranchPayload, thunkAPI) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/branches/${payload.id}`,
      {
        method: 'DELETE',
        headers: getDefaultHeaders(),
      },
    );

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return thunkAPI.fulfillWithValue(payload.id);
  },
);
