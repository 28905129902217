import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Grid from '@mui/material/Grid2';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { projects } from 'store/projects/selectors';
import { t } from 'i18next';
import { setCurrentProjectById } from 'store/projects/projects';
import { Project } from 'store/projects/interfaces';
import ProjectDialog from 'components/Dialogs/ProjectDialog/ProjectDialog';

function ProjectSelection() {
  const allProjects = useAppSelector(projects);
  const dispatch = useAppDispatch();

  const [isNewProjectDialogOpen, setIsNewProjectDialogOpen] = useState(false);

  const handleSelectProject = (project: Project) => {
    dispatch(setCurrentProjectById({ projectId: project.id }));
  };

  if (!allProjects) {
    return null;
  }

  return (
    <>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Grid size={{ xs: 11, sm: 6 }}>
          <Grid
            container
            spacing={4}
            display="flex"
            alignItems="center"
            flexDirection="column"
            py={2}
          >
            {allProjects.length ? (
              <>
                <Box display="flex" width="100%" justifyContent="center">
                  <Typography variant="h4" textAlign="center" color="primary">
                    {t('choose_project')}:
                  </Typography>
                </Box>
                <Box display="flex" width="100%">
                  <Grid
                    container
                    spacing={4}
                    display="flex"
                    justifyContent="center"
                    width="100%"
                  >
                    {allProjects.map((project) => (
                      <Grid key={project.id} size={{ xs: 12, md: 6, lg: 4 }}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => handleSelectProject(project)}
                        >
                          {project.name}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </>
            ) : (
              <Box display="flex" width="100%" justifyContent="center">
                <Typography variant="h4" textAlign="center" color="primary">
                  {t('no_projects')}
                </Typography>
              </Box>
            )}
            <Button
              variant="outlined"
              endIcon={<AddCircleIcon />}
              sx={{
                width: 'min-content',
                textWrap: 'nowrap',
              }}
              onClick={() => setIsNewProjectDialogOpen(true)}
            >
              {t('add_new_project')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <ProjectDialog
        isDialogOpen={isNewProjectDialogOpen}
        handleDialogClose={() => setIsNewProjectDialogOpen(false)}
      />
    </>
  );
}

export default ProjectSelection;
