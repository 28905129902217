import React, { useEffect, forwardRef } from 'react';
import {
  Controller,
  FieldError,
  useForm,
  UseFormSetError,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  TextField,
} from '@mui/material';
import { t } from 'i18next';
import { InferType } from 'yup';
import { getErrorText } from 'helpers/getErrorText';
import projectFormSchema from 'components/Forms/ProjectForm/utils/ProjectFormSchema';
import defaultValues from 'components/Forms/ProjectForm/utils/defaultValues';
import { Language, Project } from 'store/projects/interfaces';

export type FormData = InferType<typeof projectFormSchema>;

interface ProjectFormProps {
  onSubmit: (data: FormData, setError: UseFormSetError<FormData>) => void;
  projectToEdit?: Project | null;
}

const ProjectForm = forwardRef<HTMLFormElement, ProjectFormProps>(
  ({ onSubmit, projectToEdit }, ref) => {
    const {
      control,
      handleSubmit,
      formState: { errors },
      setError,
      reset,
    } = useForm<FormData>({
      resolver: yupResolver(projectFormSchema),
      mode: 'onBlur',
      defaultValues,
    });

    useEffect(() => {
      if (!projectToEdit) {
        return;
      }
      reset(projectToEdit);
    }, [projectToEdit, reset]);

    const handleLanguageChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      fieldValue: Language[] = [],
      language: Language,
    ) => {
      if (event.target.checked) {
        return [...fieldValue, language];
      } else {
        return fieldValue.filter((item) => item !== language);
      }
    };

    return (
      <Box
        component="form"
        display="flex"
        width="100%"
        flexDirection="column"
        gap={2}
        onSubmit={handleSubmit((data) => onSubmit(data, setError))}
        ref={ref}
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              label={t('project_name')}
              variant="outlined"
              error={!!errors.name}
              helperText={getErrorText(errors.name)}
            />
          )}
        />
        <Controller
          name="availableLanguages"
          control={control}
          render={({ field }) => (
            <FormControl
              fullWidth
              error={!!errors.availableLanguages}
              component="fieldset"
            >
              <FormLabel>{t('select_available_languages')}:</FormLabel>
              <FormGroup>
                {Object.values(Language).map((language) => (
                  <FormControlLabel
                    {...field}
                    key={language}
                    control={
                      <Checkbox
                        checked={field.value?.includes(language)}
                        onChange={(event) =>
                          field.onChange(
                            handleLanguageChange(event, field.value, language),
                          )
                        }
                        name={language}
                      />
                    }
                    label={t(`language_${language}`)}
                  />
                ))}
              </FormGroup>
              {errors.availableLanguages && (
                <FormHelperText>
                  {getErrorText(errors.availableLanguages as FieldError)}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Box>
    );
  },
);

ProjectForm.displayName = 'ProjectForm';

export default ProjectForm;
