import React, { forwardRef } from 'react';
import Grid from '@mui/material/Grid2';
import { TextField } from '@mui/material';
import { t } from 'i18next';
import Markdown from 'components/MarkdownEditor/styled/MarkdownEditor.styled';
import { ControllerRenderProps, FieldErrors } from 'react-hook-form';
import { getErrorText } from 'helpers/getErrorText';
import { isFieldError } from 'helpers/isFieldError';
import getMuiComponents from 'components/MarkdownEditor/utils/getMuiComponents';
import { diffTrimmedLines } from 'diff';
import { unified } from 'unified';
import parseMarkdown from 'remark-parse';
import rehypeStringify from 'rehype-stringify';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import remarkRehype from 'remark-rehype';
import rehypeRaw from 'rehype-raw';

interface MarkdownEditorProps extends ControllerRenderProps {
  errors: FieldErrors;
  existingContent?: string;
}

const MarkdownEditor = forwardRef<HTMLDivElement, MarkdownEditorProps>(
  (
    { errors, onChange, onBlur, value, name, disabled, existingContent },
    ref,
  ) => {
    const contentError = isFieldError(errors.content)
      ? errors.content
      : undefined;

    const renderDiffMarkdown = () => {
      if (!existingContent) {
        return value;
      }

      return diffTrimmedLines(existingContent, value)
        .map((part) => {
          const htmlContent = unified()
            .use(parseMarkdown)
            .use(remarkGfm)
            .use(remarkBreaks)
            .use(remarkRehype)
            .use(rehypeStringify)
            .processSync(part.value)
            .toString();

          if (part.added) {
            return `<div class="added-part">${htmlContent}</div>`;
          } else if (part.removed) {
            return `<div class="removed-part">${htmlContent}</div>`;
          }

          return htmlContent;
        })
        .join('');
    };

    return (
      <Grid container spacing={1.5} sx={{ height: { xs: 400, sm: '100%' } }}>
        <Grid
          size={{ xs: 12, sm: 6 }}
          sx={{ height: { xs: '50%', sm: 'unset' } }}
        >
          <TextField
            multiline
            fullWidth
            type="text"
            label={t('content')}
            variant="outlined"
            error={!!errors.content}
            helperText={getErrorText(contentError)}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            name={name}
            disabled={disabled}
            inputRef={ref}
            sx={{
              height: '100%',
              '& .MuiOutlinedInput-root': {
                height: '100%',
              },
              textarea: {
                height: '100% !important',
              },
            }}
          />
        </Grid>
        <Grid
          size={{ xs: 12, sm: 6 }}
          sx={{ height: { xs: '50%', sm: 'unset' } }}
        >
          <Markdown
            remarkPlugins={[remarkGfm, remarkBreaks, remarkRehype]}
            rehypePlugins={[rehypeRaw]}
            components={getMuiComponents()}
          >
            {renderDiffMarkdown()}
          </Markdown>
        </Grid>
      </Grid>
    );
  },
);

MarkdownEditor.displayName = 'MarkdownEditor';

export default MarkdownEditor;
