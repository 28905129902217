import * as yup from 'yup';
import { Language } from 'store/projects/interfaces';

const projectFormSchema: yup.ObjectSchema<{
  name: string;
  availableLanguages: Language[];
}> = yup.object().shape({
  name: yup.string().required('project_name_required'),
  availableLanguages: yup
    .array(yup.string().oneOf(Object.values(Language)).required())
    .min(1, 'project_languages_required')
    .required(),
});

export default projectFormSchema;
