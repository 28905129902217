import React from 'react';
import { createSvgIcon } from '@mui/material';

const BranchIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66667 3.81818C6.17575 3.81818 5.77778 4.2252 5.77778 4.72727C5.77778 5.22935 6.17575 5.63636 6.66667 5.63636C7.15759 5.63636 7.55556 5.22935 7.55556 4.72727C7.55556 4.2252 7.15759 3.81818 6.66667 3.81818ZM4 4.72727C4 3.22104 5.19391 2 6.66667 2C8.13943 2 9.33333 3.22104 9.33333 4.72727C9.33333 5.91474 8.59128 6.92496 7.55556 7.29936V12.1114C8.3447 11.4543 9.33486 11.0909 10.3616 11.0909H15.5556C16.0465 11.0909 16.4444 10.6839 16.4444 10.1818V7.29936C15.4087 6.92496 14.6667 5.91474 14.6667 4.72727C14.6667 3.22104 15.8606 2 17.3333 2C18.8061 2 20 3.22104 20 4.72727C20 5.91474 19.2579 6.92496 18.2222 7.29936V10.1818C18.2222 11.6881 17.0283 12.9091 15.5556 12.9091H10.3616C9.6544 12.9091 8.97613 13.1964 8.47603 13.7079L7.55556 14.6493V16.7006C8.59128 17.075 9.33333 18.0853 9.33333 19.2727C9.33333 20.779 8.13943 22 6.66667 22C5.19391 22 4 20.779 4 19.2727C4 18.0853 4.74205 17.075 5.77778 16.7006V7.29936C4.74205 6.92496 4 5.91474 4 4.72727ZM17.3333 3.81818C16.8424 3.81818 16.4444 4.2252 16.4444 4.72727C16.4444 5.22935 16.8424 5.63636 17.3333 5.63636C17.8243 5.63636 18.2222 5.22935 18.2222 4.72727C18.2222 4.2252 17.8243 3.81818 17.3333 3.81818ZM6.66667 18.3636C6.17575 18.3636 5.77778 18.7707 5.77778 19.2727C5.77778 19.7748 6.17575 20.1818 6.66667 20.1818C7.15759 20.1818 7.55556 19.7748 7.55556 19.2727C7.55556 18.7707 7.15759 18.3636 6.66667 18.3636Z"
      fill="inherit"
    />
  </svg>,
  'BranchIcon',
);

export default BranchIcon;
