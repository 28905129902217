import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import defaultState from 'store/organizations/defaultState';
import { fetchOrganizations } from 'store/organizations/thunks';

export const organizations = createSlice({
  name: 'organizations',
  initialState: defaultState,
  reducers: {
    setCurrentOrganizationById: (
      draft,
      action: PayloadAction<{ organizationId: string | null }>,
    ) => {
      const chosenOrganization = draft.organizations?.find(
        (organization) => organization.id === action.payload.organizationId,
      );

      draft.currentOrganization = chosenOrganization || null;

      if (!chosenOrganization?.id) {
        return;
      }

      localStorage.setItem('organization', chosenOrganization.id);
    },

    resetOrganizationsState: (draft) => {
      draft.organizations = defaultState.organizations;
      draft.currentOrganization = defaultState.currentOrganization;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrganizations.pending, (draft) => {
      draft.isRequestPending = true;
    });
    builder.addCase(fetchOrganizations.fulfilled, (draft, action) => {
      const { 'hydra:member': organizations } = action.payload;
      draft.organizations = organizations;

      const organizationId = localStorage.getItem('organization');

      const chosenOrganization = draft.organizations.find(
        (organization) => organization.id === organizationId,
      );

      draft.currentOrganization = chosenOrganization || null;

      draft.isRequestPending = false;
    });
    builder.addCase(fetchOrganizations.rejected, (draft) => {
      draft.isRequestPending = false;
    });
  },
});

export const { setCurrentOrganizationById, resetOrganizationsState } =
  organizations.actions;

export default organizations.reducer;
