import * as yup from 'yup';
import { Language } from 'store/projects/interfaces';

const articleFormSchema = yup.object().shape({
  language: yup.string().oneOf(Object.values(Language)).required(),
  position: yup.number().min(1, 'min_value_greater_than_zero').required(),
  title: yup.string().required('article_title_required'),
  branch: yup.string().required().nullable(),
  slug: yup.string().optional(),
  readingTime: yup.number().min(1, 'min_value_greater_than_zero').required(),
  parent: yup.string().required().nullable(),
  origin: yup.string().required().nullable(),
  content: yup.string().required('article_content_required'),
});

export default articleFormSchema;
