import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import useLocalStorageSync from 'hooks/useLocalStorageSync';
import routes from 'config/routing/routing';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  currentOrganization,
  isRequestPending,
  organizations,
} from 'store/organizations/selectors';
import { currentProject } from 'store/projects/selectors';
import { fetchOrganizations } from 'store/organizations/thunks';
import { fetchProjects } from 'store/projects/thunks';
import { currentUser, isAuthorized } from 'store/authorization/selectors';
import AppLoader from 'components/AppLoader/AppLoader';
import OrganizationSelection from 'components/OrganizationSelection/OrganizationSelection';
import ProjectSelection from 'components/ProjectSelection/ProjectSelection';
import { fetchCurrentUser } from 'store/authorization/thunks';

export default function App() {
  useLocalStorageSync();

  const isUserAuthorized = useAppSelector(isAuthorized);
  const allOrganizations = useAppSelector(organizations);
  const organizationsRequestPending = useAppSelector(isRequestPending);
  const organization = useAppSelector(currentOrganization);
  const project = useAppSelector(currentProject);
  const user = useAppSelector(currentUser);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!user && isUserAuthorized) {
      dispatch(fetchCurrentUser());
    }
  }, [dispatch, isUserAuthorized, user]);

  useEffect(() => {
    if (!allOrganizations && isUserAuthorized) {
      dispatch(fetchOrganizations());
    }

    if (organization?.id && isUserAuthorized) {
      dispatch(fetchProjects({ organization: organization.id }));
    }
  }, [allOrganizations, organization?.id, isUserAuthorized, dispatch]);

  if (organizationsRequestPending) {
    return <AppLoader />;
  }

  if (!organization && isUserAuthorized) {
    return <OrganizationSelection />;
  }

  if (!project && isUserAuthorized) {
    return <ProjectSelection />;
  }

  return (
    <Routes>
      {routes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}
