import React, { useRef } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { t } from 'i18next';
import ArticleForm from 'components/Forms/ArticleForm/ArticleForm';
import { FormData } from 'components/Forms/ArticleForm/ArticleForm';
import mapViolationsToFormErrors from 'helpers/mapViolationsToFormErrors';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { UseFormSetError } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { isRequestPending } from 'store/articles/selectors';
import { currentProject } from 'store/projects/selectors';
import { createArticle, editArticle } from 'store/articles/thunks';
import { Article } from 'store/articles/interfaces';

interface NewArticleDialogProps {
  isDialogOpen: boolean;
  handleDialogClose: () => void;
  articleToEdit: Article | null;
}

function ArticleDialog({
  isDialogOpen,
  handleDialogClose,
  articleToEdit,
}: NewArticleDialogProps) {
  const requestPending = useAppSelector(isRequestPending);
  const project = useAppSelector(currentProject);

  const dispatch = useAppDispatch();
  const { displaySuccessNotification, displayErrorNotification } =
    useDisplayNotification();

  const formRef = useRef<HTMLFormElement | null>(null);

  const handleFormSubmit = async (
    formData: FormData,
    setError: UseFormSetError<FormData>,
  ) => {
    if (!project) {
      return;
    }

    try {
      if (!articleToEdit) {
        await dispatch(
          createArticle({
            ...formData,
            project: project['@id'],
          }),
        ).unwrap();
      } else {
        const { branch, origin, ...editFormData } = formData;
        await dispatch(
          editArticle({
            ...editFormData,
            id: articleToEdit.id,
          }),
        ).unwrap();
      }

      displaySuccessNotification(t('success'));
      handleDialogClose();
    } catch (error) {
      mapViolationsToFormErrors<FormData>(error, setError);
      displayErrorNotification(error);
    }
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="xl"
      PaperProps={{ sx: { minHeight: '80vh' } }}
    >
      <DialogTitle sx={{ pb: 1 }} color="primary">
        {articleToEdit ? t('edit_article') : t('new_article')}
      </DialogTitle>
      <DialogContent sx={{ pb: 1, display: 'flex' }}>
        <Box sx={{ pt: 1, width: '100%' }}>
          <ArticleForm
            onSubmit={handleFormSubmit}
            ref={formRef}
            articleToEdit={articleToEdit}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} disabled={requestPending}>
          {t('cancel')}
        </Button>
        <Button autoFocus onClick={handleSubmitClick} disabled={requestPending}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ArticleDialog;
