import React from 'react';
import Grid from '@mui/material/Grid2';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import Link from 'components/Link/Link';
import { getErrorText } from 'helpers/getErrorText';
import passwordResetSchema from 'pages/PasswordReset/utils/passwordResetFormSchema';
import { InferType } from 'yup';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { passwordReset } from 'store/authorization/thunks';
import { isRequestPending } from 'store/authorization/selectors';
import mapViolationsToFormErrors from 'helpers/mapViolationsToFormErrors';

type FormData = InferType<typeof passwordResetSchema>;

function PasswordReset() {
  const requestPending = useAppSelector(isRequestPending);

  const dispatch = useAppDispatch();
  const { displaySuccessNotification, displayErrorNotification } =
    useDisplayNotification();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm<FormData>({
    resolver: yupResolver(passwordResetSchema),
    mode: 'onBlur',
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: FormData) => {
    try {
      await dispatch(
        passwordReset({
          email: data.email,
        }),
      ).unwrap();

      displaySuccessNotification(t('password_reset_instructions'));
      reset();
    } catch (error) {
      displayErrorNotification(error);
      mapViolationsToFormErrors<FormData>(error, setError);
    }
  };

  return (
    <Grid
      container
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Grid size={{ xs: 11, sm: 6, md: 4 }}>
        <Grid container spacing={4} display="flex" flexDirection="column">
          <Box display="flex" width="100%" justifyContent="center">
            <Typography variant="h3" textAlign="center" color="primary">
              {t('password_reset')}
            </Typography>
          </Box>
          <Box
            component="form"
            display="flex"
            width="100%"
            flexDirection="column"
            gap={2}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="email"
                  label={t('email')}
                  variant="outlined"
                  error={!!errors.email}
                  helperText={getErrorText(errors.email)}
                />
              )}
            />
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={requestPending}
            >
              {t('send')}
            </Button>
          </Box>
          <Box display="flex" gap={1}>
            <Typography variant="body1">{t('come_back_to_sign_in')}</Typography>
            <Link to="/login" label={t('sign_in')} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PasswordReset;
