import React from 'react';
import Layout from 'components/Layout/Layout';
import RoundedBox from 'common/RoundedBox/RoundedBox';
import { Typography } from '@mui/material';

function Settings() {
  return (
    <Layout>
      <RoundedBox sx={{ p: 1 }}>
        <Typography variant="h3">Settings - WIP</Typography>
      </RoundedBox>
    </Layout>
  );
}

export default Settings;
