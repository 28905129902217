import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import defaultState from 'store/notifications/defaultState';
import { AppNotificationPayload } from 'store/notifications/interfaces';

export const notifications = createSlice({
  name: 'notifications',
  initialState: defaultState,
  reducers: {
    showNotification: (
      draft,
      action: PayloadAction<AppNotificationPayload>,
    ) => {
      const { type, message } = action.payload;
      draft.type = type;
      draft.message = message;
      draft.isOpen = true;
    },

    hideNotification: (draft) => {
      draft.type = undefined;
      draft.message = '';
      draft.isOpen = false;
    },
  },
});

export const { showNotification, hideNotification } = notifications.actions;

export default notifications.reducer;
