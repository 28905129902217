import React, { ReactNode } from 'react';
import { ButtonBase } from '@mui/material';
import theme from 'config/mui/theme';

interface ActionButtonProps {
  children: ReactNode;
}

function ActionButton({ children }: ActionButtonProps) {
  return (
    <ButtonBase
      sx={{
        minHeight: 44,
        borderRadius: theme.shape['borderRadius-s'],
        width: '100%',
        px: 2,
        py: 1,
        gap: 1,
        justifyContent: 'flex-start',
        '&:hover': {
          bgcolor: theme.palette.action.hover,
        },
      }}
    >
      {children}
    </ButtonBase>
  );
}

export default ActionButton;
