import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDefaultHeaders } from 'store/utils/getDefaultHeaders';
import { ApiResponse } from 'helpers/interfaces';
import { Organization } from 'store/organizations/interfaces';

export const fetchOrganizations = createAsyncThunk(
  'organizations/fetchOrganizations',
  async (_, thunkAPI) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/organizations`,
      {
        headers: getDefaultHeaders(),
      },
    );

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return thunkAPI.fulfillWithValue(data as ApiResponse<Organization>);
  },
);
