import React from 'react';
import RoundedBox from 'common/RoundedBox/RoundedBox';
import IconButton from '@mui/material/IconButton';
import CodeIcon from '@mui/icons-material/Code';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import TextDecreaseIcon from '@mui/icons-material/TextDecrease';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Tooltip } from '@mui/material';
import { t } from 'i18next';
import { FormatItalic } from '@mui/icons-material';

function ControlsSection() {
  const controls = [
    {
      label: t('text_increase'),
      icon: <TextIncreaseIcon />,
    },
    {
      label: t('text_decrease'),
      icon: <TextDecreaseIcon />,
    },
    {
      label: t('numbered_list'),
      icon: <FormatListNumberedIcon />,
    },
    {
      label: t('bullet_list'),
      icon: <FormatListBulletedIcon />,
    },
    {
      label: t('text_bold'),
      icon: <FormatBoldIcon />,
    },
    {
      label: t('text_italic'),
      icon: <FormatItalic />,
    },
    {
      label: t('text_underline'),
      icon: <FormatUnderlinedIcon />,
    },
    {
      label: t('code'),
      icon: <CodeIcon />,
    },
  ];

  return (
    <RoundedBox sx={{ p: 1, width: '100%' }}>
      {controls.map((control) => (
        <Tooltip key={control.label} title={control.label}>
          <IconButton disableRipple>{control.icon}</IconButton>
        </Tooltip>
      ))}
    </RoundedBox>
  );
}

export default ControlsSection;
