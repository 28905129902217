import { Article } from 'store/articles/interfaces';

function groupArticlesByOrigin(articles: Article[] | null): Article[][] {
  if (!articles) {
    return [];
  }

  const groups: Article[][] = [];
  const articleMap: Map<string, Article[]> = new Map();

  articles.forEach((article) => {
    if (!article.origin) {
      const newGroup = [article];
      groups.push(newGroup);
      articleMap.set(article['@id'], newGroup);
      return;
    }

    const parentGroup = articleMap.get(article.origin);
    if (parentGroup) {
      parentGroup.push(article);
      return;
    }

    groups.push([article]);
    articleMap.set(article['@id'], [article]);
  });

  return groups;
}

export default groupArticlesByOrigin;
