import { useAppDispatch } from 'store/hooks';
import { resetProjectsState } from 'store/projects/projects';
import { resetAuthorizationState } from 'store/authorization/authorization';
import { resetOrganizationsState } from 'store/organizations/organizations';
import { resetArticlesState } from 'store/articles/articles';
import { resetBranchesState } from 'store/branches/branches';

export default function useResetState() {
  const dispatch = useAppDispatch();

  return () => {
    dispatch(resetProjectsState());
    dispatch(resetAuthorizationState());
    dispatch(resetOrganizationsState());
    dispatch(resetArticlesState());
    dispatch(resetBranchesState());
  };
}
