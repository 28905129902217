import React from 'react';
import Layout from 'components/Layout/Layout';
import { Typography } from '@mui/material';
import RoundedBox from 'common/RoundedBox/RoundedBox';

function Home() {
  return (
    <Layout>
      <RoundedBox sx={{ p: 1 }}>
        <Typography variant="h3">Home - WIP</Typography>
      </RoundedBox>
    </Layout>
  );
}

export default Home;
