import * as yup from 'yup';
import { passwordRegex } from 'helpers/regexes';

const passwordSetupSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'password_min_length')
    .matches(passwordRegex, 'password_not_complex_enough')
    .required('password_min_length'),
  confirm: yup
    .string()
    .min(8, 'password_min_length')
    .oneOf([yup.ref('password'), undefined], 'passwords_do_not_match')
    .required('password_min_length'),
});

export default passwordSetupSchema;
