import { RootState } from 'store';

export const isRequestPending = (state: RootState) =>
  state.organizations.isRequestPending;

export const organizations = (state: RootState) =>
  state.organizations.organizations;

export const currentOrganization = (state: RootState) =>
  state.organizations.currentOrganization;
