import React, { useRef } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { t } from 'i18next';
import ProjectForm, {
  FormData,
} from 'components/Forms/ProjectForm/ProjectForm';
import mapViolationsToFormErrors from 'helpers/mapViolationsToFormErrors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { isRequestPending } from 'store/projects/selectors';
import { createProject, editProject } from 'store/projects/thunks';
import { currentOrganization } from 'store/organizations/selectors';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { UseFormSetError } from 'react-hook-form';
import { Project } from 'store/projects/interfaces';

interface ProjectDialogProps {
  isDialogOpen: boolean;
  handleDialogClose: () => void;
  projectToEdit?: Project | null;
}

function ProjectDialog({
  isDialogOpen,
  handleDialogClose,
  projectToEdit = null,
}: ProjectDialogProps) {
  const requestPending = useAppSelector(isRequestPending);
  const organization = useAppSelector(currentOrganization);

  const dispatch = useAppDispatch();
  const { displaySuccessNotification, displayErrorNotification } =
    useDisplayNotification();

  const formRef = useRef<HTMLFormElement | null>(null);

  const handleFormSubmit = async (
    formData: FormData,
    setError: UseFormSetError<FormData>,
  ) => {
    if (!organization) {
      return;
    }

    try {
      if (!projectToEdit) {
        await dispatch(
          createProject({
            ...formData,
            organization: organization['@id'],
          }),
        ).unwrap();
      } else {
        await dispatch(
          editProject({
            ...formData,
            id: projectToEdit.id,
          }),
        ).unwrap();
      }

      displaySuccessNotification(t('success'));
      handleDialogClose();
    } catch (error) {
      mapViolationsToFormErrors<FormData>(error, setError);
      displayErrorNotification(error);
    }
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle sx={{ pb: 1 }} color="primary">
        {projectToEdit ? t('edit_project') : t('add_new_project')}
      </DialogTitle>
      <DialogContent sx={{ pb: 1 }}>
        <Box pt={1}>
          <ProjectForm
            onSubmit={handleFormSubmit}
            ref={formRef}
            projectToEdit={projectToEdit}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} disabled={requestPending}>
          {t('cancel')}
        </Button>
        <Button autoFocus onClick={handleSubmitClick} disabled={requestPending}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ProjectDialog;
