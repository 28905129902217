import * as yup from 'yup';
import { passwordRegex } from 'helpers/regexes';

const signUpSchema = yup.object().shape({
  firstname: yup.string().required('first_name_required'),
  lastname: yup.string().required('last_name_required'),
  email: yup.string().email('invalid_email').required('invalid_email'),
  password: yup
    .string()
    .min(8, 'password_min_length')
    .matches(passwordRegex, 'password_not_complex_enough')
    .required('password_min_length'),
  confirm_password: yup
    .string()
    .min(8, 'password_min_length')
    .oneOf([yup.ref('password'), undefined], 'passwords_do_not_match')
    .required('password_min_length'),
});

export default signUpSchema;
