import { AuthorizationState } from 'store/authorization/interfaces';

const defaultState: AuthorizationState = {
  isAuthorized: false,
  token: null,
  isRequestPending: false,
  currentUser: null,
};

export default defaultState;
