import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import { Box, CircularProgress, Typography } from '@mui/material';
import { t } from 'i18next';
import Link from 'components/Link/Link';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { accountActivation } from 'store/authorization/thunks';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { isRequestPending } from 'store/authorization/selectors';

function AccountActivation() {
  const { token } = useParams<{ token: string }>();

  const dispatch = useAppDispatch();
  const requestPending = useAppSelector(isRequestPending);
  const [isAccountActivationError, setIsAccountActivationError] =
    useState(false);

  useEffect(() => {
    if (!token) {
      return;
    }

    const activateAccount = async () => {
      try {
        await dispatch(accountActivation({ token })).unwrap();
      } catch (error) {
        setIsAccountActivationError(true);
      }
    };

    activateAccount();
  }, [dispatch, token]);

  return (
    <Grid
      container
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Grid size={{ xs: 11, sm: 6, md: 4 }}>
        <Grid container spacing={4} display="flex" flexDirection="column">
          <Box display="flex" width="100%" justifyContent="center">
            <Typography variant="h3" textAlign="center" color="primary">
              {t('account_activation')}
            </Typography>
          </Box>
          <Box
            display="flex"
            width="100%"
            height="6rem"
            justifyContent="center"
            alignItems="center"
          >
            {requestPending && <CircularProgress color="primary" size={50} />}
            {!requestPending && !isAccountActivationError && (
              <DoneIcon color="primary" sx={{ fontSize: '5rem' }} />
            )}
            {!requestPending && isAccountActivationError && (
              <CloseIcon color="primary" sx={{ fontSize: '5rem' }} />
            )}
          </Box>
          <Box display="flex" justifyContent="center" gap={1}>
            <Typography variant="body1">{t('come_back_to_sign_in')}</Typography>
            <Link to="/login" label={t('sign_in')} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AccountActivation;
