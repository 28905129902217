import React from 'react';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/material';
import theme from 'config/mui/theme';

interface RoundedBoxProps {
  children?: React.ReactNode;
  sx?: SxProps;
}

function RoundedBox({ children, sx }: RoundedBoxProps) {
  return (
    <Box
      sx={{
        borderRadius: theme.shape['borderRadius-m'],
        border: `1px solid ${theme.vars.palette.divider}`,
        backgroundColor: theme.vars.palette.background.default,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

export default RoundedBox;
