import React from 'react';
import ReactDOM from 'react-dom/client';
import 'styles/index.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import translations from 'translations';
import theme from 'config/mui/theme';
import store from 'store';
import App from 'App';
import AppSnackbar from 'components/AppSnackbar/AppSnackbar';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  resources: translations,
});

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={theme} defaultMode="system">
            <AppSnackbar />
            <CssBaseline />
            <App />
          </ThemeProvider>
        </I18nextProvider>
      </Provider>
    </BrowserRouter>,
  );
} else {
  console.error('Root element not found');
}
