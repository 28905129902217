import { RootState } from 'store';

export const isAuthorized = (state: RootState) =>
  state.authorization.isAuthorized;

export const token = (state: RootState) => state.authorization.token;

export const isRequestPending = (state: RootState) =>
  state.authorization.isRequestPending;

export const currentUser = (state: RootState) =>
  state.authorization.currentUser;
