import React from 'react';
import { createSvgIcon } from '@mui/material';

const HistoryIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 7C12.5523 7 13 7.44772 13 8V11.3787C13 11.5113 13.0527 11.6385 13.1464 11.7322L14.7071 13.2929C15.0976 13.6834 15.0976 14.3166 14.7071 14.7071C14.3166 15.0976 13.6834 15.0976 13.2929 14.7071L11.7322 13.1464C11.2634 12.6776 11 12.0417 11 11.3787V8C11 7.44772 11.4477 7 12 7Z"
      fill="inherit"
    />
  </svg>,
  'HistoryIcon',
);

export default HistoryIcon;
