import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Grid from '@mui/material/Grid2';
import { t } from 'i18next';
import { organizations } from 'store/organizations/selectors';
import { Organization } from 'store/organizations/interfaces';
import { setCurrentOrganizationById } from 'store/organizations/organizations';

function OrganizationSelection() {
  const allOrganizations = useAppSelector(organizations);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (allOrganizations?.length === 1) {
      dispatch(
        setCurrentOrganizationById({ organizationId: allOrganizations[0].id }),
      );
    }
  }, [allOrganizations, dispatch]);

  const handleSelectOrganization = (organization: Organization) => {
    dispatch(setCurrentOrganizationById({ organizationId: organization.id }));
  };

  if (!allOrganizations) {
    return null;
  }

  return (
    <Grid
      container
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Grid size={{ xs: 11, sm: 6 }}>
        <Grid container spacing={4} display="flex" flexDirection="column">
          <Box display="flex" width="100%" justifyContent="center">
            <Typography variant="h4" textAlign="center" color="primary">
              {t('choose_organization')}:
            </Typography>
          </Box>
          <Box display="flex" width="100%">
            <Grid
              container
              spacing={4}
              display="flex"
              justifyContent="center"
              width="100%"
            >
              {allOrganizations.map((organization) => (
                <Grid key={organization.id} size={{ xs: 12, md: 6, lg: 4 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => handleSelectOrganization(organization)}
                  >
                    {organization.name}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default OrganizationSelection;
