import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { notifications } from 'store/notifications/selectors';
import { hideNotification } from 'store/notifications/notifiactions';
import { Snackbar, Alert } from '@mui/material';
import { t } from 'i18next';

function AppSnackbar() {
  const { type, isOpen, message } = useAppSelector(notifications);
  const dispatch = useAppDispatch();

  return type ? (
    <Snackbar
      open={isOpen}
      onClose={() => dispatch(hideNotification())}
      autoHideDuration={5000}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      sx={{ maxWidth: '35rem' }}
    >
      <Alert severity={type}>{t(message)}</Alert>
    </Snackbar>
  ) : null;
}

export default AppSnackbar;
