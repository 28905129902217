import React from 'react';
import Grid from '@mui/material/Grid2';
import { Box, Button, TextField, Typography } from '@mui/material';
import { t } from 'i18next';
import Link from 'components/Link/Link';
import { useParams } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { getErrorText } from 'helpers/getErrorText';
import { yupResolver } from '@hookform/resolvers/yup';
import { passwordSetup } from 'store/authorization/thunks';
import mapViolationsToFormErrors from 'helpers/mapViolationsToFormErrors';
import { InferType } from 'yup';
import passwordSetupSchema from 'pages/PasswordSetup/utils/passwordSetupSchema';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import useDisplayNotification from 'hooks/useDisplayNotification';
import { isRequestPending } from 'store/authorization/selectors';

type FormData = InferType<typeof passwordSetupSchema>;

function PasswordSetup() {
  const { token } = useParams<{ token: string }>();

  const { displaySuccessNotification, displayErrorNotification } =
    useDisplayNotification();

  const requestPending = useAppSelector(isRequestPending);
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(passwordSetupSchema),
    mode: 'onBlur',
    defaultValues: {
      password: '',
      confirm: '',
    },
  });

  const onSubmit = async (data: FormData) => {
    if (!token) {
      return;
    }

    try {
      await dispatch(
        passwordSetup({
          token: token,
          password: data.password,
          confirm: data.confirm,
        }),
      ).unwrap();

      reset();
      displaySuccessNotification(t('success'));
    } catch (error) {
      mapViolationsToFormErrors<FormData>(error, setError);
      displayErrorNotification(error);
    }
  };

  return (
    <Grid
      container
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Grid size={{ xs: 11, sm: 6, md: 4 }}>
        <Grid container spacing={4} display="flex" flexDirection="column">
          <Box display="flex" width="100%" justifyContent="center">
            <Typography variant="h3" textAlign="center" color="primary">
              {t('password_setup')}
            </Typography>
          </Box>
          <Box
            component="form"
            display="flex"
            width="100%"
            flexDirection="column"
            gap={2}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="password"
                  label={t('password')}
                  variant="outlined"
                  error={!!errors.password}
                  helperText={getErrorText(errors.password)}
                />
              )}
            />
            <Controller
              name="confirm"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="password"
                  label={t('confirm_password')}
                  variant="outlined"
                  error={!!errors.confirm}
                  helperText={getErrorText(errors.confirm)}
                />
              )}
            />
            <Button
              type="submit"
              variant="contained"
              size="large"
              disabled={requestPending}
            >
              {t('sign_in')}
            </Button>
          </Box>
          <Box display="flex" justifyContent="center" gap={1}>
            <Typography variant="body1">{t('come_back_to_sign_in')}</Typography>
            <Link to="/login" label={t('sign_in')} />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PasswordSetup;
