import React, { Dispatch, SetStateAction } from 'react';
import { Box, Button, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import { t } from 'i18next';
import theme from 'config/mui/theme';
import groupArticlesByOrigin from 'pages/Articles/utils/groupArticlesByOrigin';
import SideSection from 'components/Layout/AppDrawer/SideSection/SideSection';
import { useAppSelector } from 'store/hooks';
import { articles } from 'store/articles/selectors';
import ActionButton from 'common/ActionButton/ActionButton';

interface SideSectionContentProps {
  setIsArticleDialogOpen: Dispatch<SetStateAction<boolean>>;
}

function SideSectionContent({
  setIsArticleDialogOpen,
}: SideSectionContentProps) {
  const allArticles = useAppSelector(articles);

  return (
    <SideSection
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: 1,
      }}
    >
      <Box>
        {allArticles?.length ? (
          groupArticlesByOrigin(allArticles).map((articleGroup) => (
            <React.Fragment key={articleGroup[0]?.id}>
              {articleGroup.map((article) => (
                <ActionButton key={article.id}>
                  <CircleTwoToneIcon
                    fontSize="small"
                    sx={{ color: theme.palette.success.lighter }}
                  />
                  <Typography
                    variant="body2"
                    color="text.primary"
                    textAlign="left"
                  >
                    {article.title}
                  </Typography>
                </ActionButton>
              ))}
            </React.Fragment>
          ))
        ) : (
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              height: 44,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {t('no_articles')}
          </Typography>
        )}
      </Box>
      <Button
        variant="contained"
        size="large"
        color="inherit"
        startIcon={<AddCircleOutlineIcon />}
        onClick={() => setIsArticleDialogOpen(true)}
        sx={{
          m: 1,
        }}
      >
        {t('new_article')}
      </Button>
    </SideSection>
  );
}

export default SideSectionContent;
