import React, { ReactNode } from 'react';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { Divider } from '@mui/material';
import DocumentlyIcon from 'assets/icons/DocumentlyIcon';
import { navWidth } from 'components/Layout/utils/consts';

interface TopBarProps {
  handleDrawerToggle: () => void;
  children: ReactNode;
}

function TopBar({ children, handleDrawerToggle }: TopBarProps) {
  return (
    <AppBar position="static" elevation={0}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          '&.MuiToolbar-root': { pl: 0, pr: 1 },
        }}
      >
        <IconButton
          disableRipple
          onClick={handleDrawerToggle}
          sx={{ width: navWidth }}
        >
          <DocumentlyIcon />
        </IconButton>
        <Divider orientation="vertical" />
        {children}
        <Divider orientation="vertical" />
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
