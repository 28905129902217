import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getDefaultHeaders,
  getDefaultUpdateHeaders,
} from 'store/utils/getDefaultHeaders';
import { ApiFilters, ApiResponse } from 'helpers/interfaces';
import {
  CreateProjectPayload,
  EditProjectPayload,
  Project,
} from 'store/projects/interfaces';
import buildQueryString from 'store/utils/buildQueryString';

export const fetchProjects = createAsyncThunk(
  'projects/fetchProjects',
  async (filters: ApiFilters<Project>, thunkAPI) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/projects${buildQueryString(filters)}`,
      {
        headers: getDefaultHeaders(),
      },
    );

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return thunkAPI.fulfillWithValue(data as ApiResponse<Project>);
  },
);

export const createProject = createAsyncThunk(
  'projects/createProject',
  async (payload: CreateProjectPayload, thunkAPI) => {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/projects`, {
      method: 'POST',
      headers: getDefaultHeaders(),
      body: JSON.stringify(payload),
    });

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return thunkAPI.fulfillWithValue(data as Project);
  },
);

export const editProject = createAsyncThunk(
  'projects/editProject',
  async (payload: EditProjectPayload, thunkAPI) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/projects/${payload.id}`,
      {
        method: 'PATCH',
        headers: getDefaultUpdateHeaders(),
        body: JSON.stringify({
          ...payload,
        }),
      },
    );

    const data = await response.json();

    if (!response.ok) {
      return thunkAPI.rejectWithValue(data);
    }

    return thunkAPI.fulfillWithValue(data as Project);
  },
);

export const exportProject = createAsyncThunk(
  'projects/exportProject',
  async (payload: { id: string }, thunkAPI) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_HOST}/projects/${payload.id}/export`,
      {
        method: 'POST',
        headers: { ...getDefaultHeaders(), Accept: 'application/pdf' },
      },
    );

    if (!response.ok) {
      const data = await response.json();
      return thunkAPI.rejectWithValue(data);
    }

    const blob = await response.blob();

    return thunkAPI.fulfillWithValue({ id: payload.id, blob });
  },
);
